import styles from "./ProfileGeneralTab.module.css";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import PROFILEPIC from "../../../../Assets/Images/ProfilePic.png";
import DriveFolderUploadIcon from '@mui/icons-material/DriveFolderUpload';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPatientGUID, getEmailExistsLoader, getGeneralInfoLoader, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { setPRGeneralInfo, setRegistrationStatus, setShowEmailExistsLoader, setShowGeneralInfoLoader } from "../../../../Actions/UtilActions";
import { getIfEmailAlreadyExists, getPatientInfo, setGeneralFormEdit, updateGeneralInfo } from "../../../../Actions";
import { getGeneralFormEdit, getIfEmailAlreadyExistsSelector, getPatientInfoSelector } from "../../../../Reducer/selectors/PatientSelector";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import IconButton from "@mui/material/IconButton";
import CircularProgress from "@material-ui/core/CircularProgress";
import { getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";

const ProfileGeneralTab = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const patientInfoObj = useSelector(getPatientInfoSelector);

    const [ehrId, setEhrId] = useState(patientInfoObj.ehrId ? patientInfoObj.ehrId : '');
    const [prefix, setPrefix] = useState([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.prefix ? patientInfoObj.contactInfo.prefix : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.prefix ? patientInfoObj.contactInfo.prefix : '' : '' }]);
    const [firstName, setFirstName] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.firstName ? patientInfoObj.contactInfo.firstName : '' : '');
    const [middleName, setMiddleName] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.middle ? patientInfoObj.contactInfo.middle : '' : '');
    const [suffix, setSuffix] = useState([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.suffix ? patientInfoObj.contactInfo.suffix : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.suffix ? patientInfoObj.contactInfo.suffix : '' : '' }]);
    const [lastName, setLastName] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.lastName ? patientInfoObj.contactInfo.lastName : '' : '');
    const [prevName, setPrevName] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.prefName ? patientInfoObj.contactInfo.prefName : '' : '');
    const [dob, setDob] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.dob ? patientInfoObj.contactInfo.dob : '' : '');
    const [ssn, setSsn] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.ssn ? patientInfoObj.contactInfo.ssn : '' : '');
    const [bioSex, setBioSex] = useState([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.gender ? patientInfoObj.contactInfo.gender : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.gender ? patientInfoObj.contactInfo.gender : '' : '' }]);
    const [genderIdentity, setGenderIdentity] = useState([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.identity ? patientInfoObj.contactInfo.identity : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.identity ? patientInfoObj.contactInfo.identity : '' : '' }]);
    const [sexOrientation, setSexOrientation] = useState([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.sexOr ? patientInfoObj.contactInfo.sexOr : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.sexOr ? patientInfoObj.contactInfo.sexOr : '' : '' }]);
    const [phNum, setPhNum] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.phone ? patientInfoObj.contactInfo.phone : '' : '');
    const [email, setEmail] = useState(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.email ? patientInfoObj.contactInfo.email : '' : '');
    const [address, setAddress] = useState(patientInfoObj.address ? patientInfoObj.address.addrLine1 ? patientInfoObj.address.addrLine1 : '' : '');
    const [address2, setAddress2] = useState(patientInfoObj.address ? patientInfoObj.address.addrLine2 ? patientInfoObj.address.addrLine2 : '' : '');
    const [city, setCity] = useState(patientInfoObj.address ? patientInfoObj.address.city ? patientInfoObj.address.city : '' : '');
    const [state, setState] = useState([{ value: patientInfoObj.address ? patientInfoObj.address.state ? patientInfoObj.address.state : '' : '', label: patientInfoObj.address ? patientInfoObj.address.state ? patientInfoObj.address.state : '' : '' }]);
    const [zip, setZip] = useState(patientInfoObj.address ? patientInfoObj.address.zip ? patientInfoObj.address.zip : '' : '');
    const [country, setCountry] = useState(patientInfoObj.address ? patientInfoObj.address.country ? patientInfoObj.address.country : '' : '');

    const [isCalOpen, setIsCalOpen] = useState(false);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isEhrIdFill, setIsEhrIdFill] = useState(true);
    const [isFirstNameFill, setIsFirstNameFill] = useState(true);
    const [isLastNameFill, setIsLastNameFill] = useState(true);
    const [isDobFill, setIsDobFill] = useState(true);
    const [isSsnFill, setIsSsnFill] = useState(true);
    const [isBioSexFill, setIsBioSexFill] = useState(true);
    const [isPhNumFill, setIsPhNumFill] = useState(true);
    const [isEmailFill, setIsEmailFill] = useState(true);
    const [isAddressFill, setIsAddressFill] = useState(true);
    const [isCityFill, setIsCityFill] = useState(true);
    const [isStateFill, setIsStateFill] = useState(true);

    const doesEmailAlreadyExists = useSelector(getIfEmailAlreadyExistsSelector);
    const [emailExistsDialog, setEmailExistsDialog] = useState(false);
    const emailExistsLoader = useSelector(getEmailExistsLoader);
    const [emailExistsFlag, setEmailExistsFlag] = useState(doesEmailAlreadyExists);

    const isGeneralFormEditable = useSelector(getGeneralFormEdit);

    const generalInfoLoader = useSelector(getGeneralInfoLoader);
    const selectedSubjectId = useSelector(getSelectedSubjectId);

    const currSubjectGUID = useSelector(getCurrentPatientGUID);

    useEffect(() => {
        setEmailExistsFlag(doesEmailAlreadyExists);
    }, [doesEmailAlreadyExists]);

    const setAllFields = () => {
        setEhrId(patientInfoObj.ehrId ? patientInfoObj.ehrId : '');
        setPrefix([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.prefix ? patientInfoObj.contactInfo.prefix : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.prefix ? patientInfoObj.contactInfo.prefix : '' : '' }]);
        setFirstName(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.firstName ? patientInfoObj.contactInfo.firstName : '' : '');
        setMiddleName(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.middle ? patientInfoObj.contactInfo.middle : '' : '');
        setSuffix([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.suffix ? patientInfoObj.contactInfo.suffix : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.suffix ? patientInfoObj.contactInfo.suffix : '' : '' }]);
        setLastName(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.lastName ? patientInfoObj.contactInfo.lastName : '' : '');
        setPrevName(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.prefName ? patientInfoObj.contactInfo.prefName : '' : '');
        setDob(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.dob ? patientInfoObj.contactInfo.dob : '' : '');
        setSsn(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.ssn ? patientInfoObj.contactInfo.ssn : '' : '');
        setBioSex([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.gender ? patientInfoObj.contactInfo.gender : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.gender ? patientInfoObj.contactInfo.gender : '' : '' }]);
        setGenderIdentity([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.identity ? patientInfoObj.contactInfo.identity : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.identity ? patientInfoObj.contactInfo.identity : '' : '' }]);
        setSexOrientation([{ value: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.sexOr ? patientInfoObj.contactInfo.sexOr : '' : '', label: patientInfoObj.contactInfo ? patientInfoObj.contactInfo.sexOr ? patientInfoObj.contactInfo.sexOr : '' : '' }]);
        setPhNum(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.phone ? patientInfoObj.contactInfo.phone : '' : '');
        setEmail(patientInfoObj.contactInfo ? patientInfoObj.contactInfo.email ? patientInfoObj.contactInfo.email : '' : '');
        setAddress(patientInfoObj.address ? patientInfoObj.address.addrLine1 ? patientInfoObj.address.addrLine1 : '' : '');
        setAddress2(patientInfoObj.address ? patientInfoObj.address.addrLine2 ? patientInfoObj.address.addrLine2 : '' : '');
        setCity(patientInfoObj.address ? patientInfoObj.address.city ? patientInfoObj.address.city : '' : '');
        setState([{ value: patientInfoObj.address ? patientInfoObj.address.state ? patientInfoObj.address.state : '' : '', label: patientInfoObj.address ? patientInfoObj.address.state ? patientInfoObj.address.state : '' : '' }]);
        setZip(patientInfoObj.address ? patientInfoObj.address.zip ? patientInfoObj.address.zip : '' : '');
        setCountry(patientInfoObj.address ? patientInfoObj.address.country ? patientInfoObj.address.country : '' : '');
    };

    const clearAllGeneralFields = () => {
        setEhrId('');
        setPrefix([]);
        setFirstName('');
        setMiddleName('');
        setSuffix([]);
        setLastName('');
        setPrevName('');
        setDob(new Date());
        setSsn('');
        setBioSex([]);
        setGenderIdentity([]);
        setSexOrientation([]);
        setPhNum('');
        setEmail('');
        setAddress('');
        setAddress2('');
        setCity('');
        setState([]);
        setZip('');
        setCountry('');
        resetReqFills();
    };

    const resetReqFills = () => {
        setIsEhrIdFill(true);
        setIsFirstNameFill(true);
        setIsLastNameFill(true);
        setIsDobFill(true);
        setIsSsnFill(true);
        setIsBioSexFill(true);
        setIsPhNumFill(true);
        setIsEmailFill(true);
        setIsAddressFill(true);
        setIsCityFill(true);
        setIsStateFill(true);
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllGeneralFields: clearAllGeneralFields,
        };
    });

    const [optionsPrefix, setOptionsPrefix] = useState([
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ]);

    const [optionsSuffix, setOptionsSuffix] = useState([
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ]);

    const [optionsBioSex, setOptionsBioSex] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Unknown', label: 'Unknown' },
    ]);

    const [optionsGenderIdentity, setOptionsGenderIdentity] = useState([
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Female-to-Male (FTM)/Transgender Male/Trans Man', label: 'Female-to-Male (FTM)/Transgender Male/Trans Man' },
        { value: 'Male-to-Female (MTF)/Transgender Female/Trans Woman', label: 'Male-to-Female (MTF)/Transgender Female/Trans Woman' },
        { value: 'Genderqueer, neither exclusively male nor female', label: 'Genderqueer, neither exclusively male nor female' },
        { value: 'Additional gender category or other', label: 'Additional gender category or other' },
        { value: 'Choose not to disclose', label: 'Choose not to disclose' },
    ]);

    const [optionsSexOrientation, setOptionsSexOrientation] = useState([
        { value: 'Lesbian, Gay or Homosexual', label: 'Lesbian, Gay or Homosexual' },
        { value: 'Straight or Heterosexual', label: 'Straight or Heterosexual' },
        { value: 'Bisexual', label: 'Bisexual' },
        { value: 'Don’t Know', label: 'Don’t Know' },
        { value: 'Something Else', label: 'Something Else' },
        { value: 'Choose not to disclose', label: 'Choose not to disclose' },
    ]);

    const [optionsStates, setOptionsStates] = useState([
        { value: 'AL', label: 'AL' },
        { value: 'AK', label: 'AK' },
        { value: 'AR', label: 'AR' },
        { value: 'AZ', label: 'AZ' },
        { value: 'CA', label: 'CA' },
        { value: 'CO', label: 'CO' },
        { value: 'CT', label: 'CT' },
        { value: 'DC', label: 'DC' },
        { value: 'DE', label: 'DE' },
        { value: 'FL', label: 'FL' },
        { value: 'GA', label: 'GA' },
        { value: 'HI', label: 'HI' },
        { value: 'IA', label: 'IA' },
        { value: 'ID', label: 'ID' },
        { value: 'IL', label: 'IL' },
        { value: 'IN', label: 'IN' },
        { value: 'KS', label: 'KS' },
        { value: 'KY', label: 'KY' },
        { value: 'LA', label: 'LA' },
        { value: 'MA', label: 'MA' },
        { value: 'MD', label: 'MD' },
        { value: 'ME', label: 'ME' },
        { value: 'MI', label: 'MI' },
        { value: 'MN', label: 'MN' },
        { value: 'MO', label: 'MO' },
        { value: 'MS', label: 'MS' },
        { value: 'MT', label: 'MT' },
        { value: 'NC', label: 'NC' },
        { value: 'NE', label: 'NE' },
        { value: 'NH', label: 'NH' },
        { value: 'NJ', label: 'NJ' },
        { value: 'NM', label: 'NM' },
        { value: 'NV', label: 'NV' },
        { value: 'NY', label: 'NY' },
        { value: 'ND', label: 'ND' },
        { value: 'OH', label: 'OH' },
        { value: 'OK', label: 'OK' },
        { value: 'OR', label: 'OR' },
        { value: 'PA', label: 'PA' },
        { value: 'RI', label: 'RI' },
        { value: 'SC', label: 'SC' },
        { value: 'SD', label: 'SD' },
        { value: 'TN', label: 'TN' },
        { value: 'TX', label: 'TX' },
        { value: 'UT', label: 'UT' },
        { value: 'VT', label: 'VT' },
        { value: 'VA', label: 'VA' },
        { value: 'WA', label: 'WA' },
        { value: 'WI', label: 'WI' },
        { value: 'WV', label: 'WV' },
        { value: 'WY', label: 'WY' },
        { value: 'Other', label: 'Other' }
    ]);

    //const refOne = useRef([]);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #dadde9',
        },
    }));

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    // useEffect(() => {
    //     document.addEventListener("keydown", hideOnEscape, true);
    //     document.addEventListener("click", hideOnClickOutside, true);
    // }, []);

    // // hide dropdown on ESC press
    // const hideOnEscape = (e) => {
    //     if (e.key === "Escape") {
    //         handleCalClick(e);
    //     }
    // };

    // // Hide dropdown on outside click
    // const hideOnClickOutside = (e) => {
    //     if (refOne.current && !refOne.current.contains(e.target)) {
    //         handleCalClick(e);
    //     }
    // };

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label && state.values[0].label.length > 20) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 20)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const setTabInfo = async () => {
        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            prefix: prefix[0] ? prefix[0].value : '',
            middleName: middleName,
            lastName: lastName,
            suffix: suffix[0] ? suffix[0].value : '',
            prefName: prevName,
            ssn: ssn,
            email: email,
            genderIdentity: genderIdentity[0] ? genderIdentity[0].value : '',
            sexOr: sexOrientation[0] ? sexOrientation[0].value : '',
            phone: phNum,
            addr1: address,
            addr2: address2,
            city: city,
            state: state[0] ? state[0].value : '',
            zip: zip,
            country: country,
        };
        console.log("generalInfoTab payload: ", payload);
        dispatch(setShowGeneralInfoLoader(true));
        dispatch(updateGeneralInfo(payload));
        dispatch(setGeneralFormEdit(false));
        await sleep(3000);
        dispatch(getPatientInfo(currSubjectGUID));
    };

    const openEmailDailog = () => {
        if (emailExistsFlag) {
            //console.log('email does exist');
            setEmailExistsDialog(true);
        } else {
            //console.log('doesEmailAlreadyExists: ', doesEmailAlreadyExists);
            //console.log('email doesnt exist!');
        };
    };

    const checkIfEmailAlreadyExists = async () => {
        let payload = {
            email: email,
        };
        dispatch(getIfEmailAlreadyExists(payload));
        await sleep(3000);
        openEmailDailog();
    };

    const checkIfPageConditionsMet = () => {
        if ((ehrId !== '') && (firstName !== '') && (lastName !== '') && (bioSex[0] && bioSex[0].value !== '') && (phNum !== '')) {
            //console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            //console.log('conditions not met!');
            resetReqFills();
            if ((ehrId === '')) {
                setIsEhrIdFill(false);
            };
            if ((firstName === '')) {
                setIsFirstNameFill(false);
            };
            if ((lastName === '')) {
                setIsLastNameFill(false);
            };
            // if ((format(dob, "MM-dd-yyyy") !== format(new Date(), "yyyy-MM-dd"))) {
            //     setIsDobFill(false);
            // };
            if ((ssn === '')) {
                setIsSsnFill(false);
            };
            if ((!bioSex[0] || bioSex[0].value === '')) {
                setIsBioSexFill(false);
            };
            if ((phNum === '')) {
                setIsPhNumFill(false);
            };
            if ((email === '')) {
                setIsEmailFill(false);
            };
            if ((address === '')) {
                setIsAddressFill(false);
            };
            if ((city === '')) {
                setIsCityFill(false);
            };
            if ((!state[0] || state[0].value === '')) {
                setIsStateFill(false);
            };
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSaveAndContinue = async () => {
        //console.log("generalInfoTab handleSaveAndContinue");
        if (checkIfPageConditionsMet()) {
            //console.log('conditions met!');
            // dispatch(setShowEmailExistsLoader(true));
            // await checkIfEmailAlreadyExists();
            // if (!emailExistsFlag) {
            setTabInfo();
            //}
        }
    };

    const cancelEdit = () => {
        setAllFields();
        dispatch(setGeneralFormEdit(false));
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    function closeEmailExistsDialog(event, reason) {
        if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
            // Set 'open' to false, however you would do that with your particular code.
            setEmailExistsDialog(false);
        }
    };

    const editGeneralForm = (event) => {
        event.preventDefault();
        dispatch(setGeneralFormEdit(true));
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={emailExistsDialog}
            ></Backdrop>
            {generalInfoLoader ? (<><div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div></>) : (<>
                <div className={styles.containerDiv}>
                    <div className={styles.headerDiv}>
                        <div></div>
                        <div>General</div>
                        <div className={styles.editButtonDiv}>
                            <div className={!isGeneralFormEditable ? styles.editButton : styles.editButtonClicked} onClick={event => editGeneralForm(event)}>Edit</div>
                        </div>
                    </div>
                    <div className={styles.dumDiv}>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        EHR or MRN ID <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                        <div className={styles.infoButton}>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <div className={styles.circlesList}>
                                                            <div className={styles.circlesListItem}>EHR - Electronic Medical Record (Hospital)</div>
                                                            <div className={styles.circlesListItem}>MRN - Medical Record Number (Nursing)</div>
                                                        </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlinedIcon style={{ height: '14px', width: '14px', fill: "#A040AB", marginRight: "5px", marginLeft: "10px" }} />
                                            </HtmlTooltip>
                                        </div>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" disabled={true} className={styles.inputFieldDisabled} value={ehrId} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isGeneralFormEditable}
                                                className={isGeneralFormEditable ? styles.dropdownE : styles.dropdown}
                                                values={prefix}
                                                onChange={setPrefix}
                                                options={optionsPrefix}
                                                //disabled={!isGeneralFormEditable}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name <span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstName} disabled={true} className={styles.inputFieldDisabled2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Middle Name
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={middleName} onChange={(e) => setMiddleName(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name <span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastName} onChange={(e) => setLastName(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelcted2E : styles.inputFieldSelcted2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                disabled={!isGeneralFormEditable}
                                                className={isGeneralFormEditable ? styles.dropdownE : styles.dropdown}
                                                values={suffix}
                                                onChange={setSuffix}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred Name
                                        {/* <div className={styles.infoButton}>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <div className={styles.circlesListItem}> Please Indicate if your last name changed </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlinedIcon style={{ height: '14px', width: '14px', fill: "#A040AB", marginRight: "5px", marginLeft: "10px" }} />
                                            </HtmlTooltip>
                                        </div> */}
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={prevName} onChange={(e) => setPrevName(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        DOB (YYYY-MM-DD) <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    {/* <div className={styles.fieldInput}>
                                        <input
                                            value={`${format(dob, "yyyy/MM/dd")}`}
                                            readOnly={true}
                                            className={styles.inputFieldSelctedDOBDisabled}
                                        // onClick={(event) => handleCalClick(event)}
                                        />
                                        {isCalOpen && (
                                            <div className={styles.datePickerWrapper}>
                                                <Calendar
                                                    onChange={item => setDob(item)}
                                                    editableDateInputs={false}
                                                    showSelectionPreview={true}
                                                    months={1}
                                                    date={dob}
                                                    direction="horizontal"
                                                    maxDate={addDays(new Date(), 1)}
                                                    color={["#4E253A"]}
                                                />
                                                <div className={styles.applyButtonDiv}>
                                                    <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                                </div>
                                            </div>
                                        )}
                                    </div> */}
                                    <div className={styles.fieldInput}>
                                        <input type="text" disabled={true} className={styles.inputFieldDisabled} value={dob} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        SSN
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={ssn} onChange={(e) => setSsn(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdownDisabled2}
                                            values={bioSex}
                                            onChange={setBioSex}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                            disabled={true}
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Gender Identity
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isGeneralFormEditable ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isGeneralFormEditable}
                                            values={genderIdentity}
                                            onChange={setGenderIdentity}
                                            options={optionsGenderIdentity}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sexual Orientation
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isGeneralFormEditable ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isGeneralFormEditable}
                                            values={sexOrientation}
                                            onChange={setSexOrientation}
                                            options={optionsSexOrientation}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNum} onChange={(e) => setPhNum(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={email} onChange={(e) => setEmail(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={address} onChange={(e) => setAddress(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Address Line 2
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={address2} onChange={(e) => setAddress2(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        City
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={city} onChange={(e) => setCity(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        State
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isGeneralFormEditable ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isGeneralFormEditable}
                                            values={state}
                                            onChange={setState}
                                            options={optionsStates}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Zip Code
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={zip} onChange={(e) => setZip(e.currentTarget.value)} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Country
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={country} disabled={!isGeneralFormEditable} className={isGeneralFormEditable ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onChange={(e) => setCountry(e.currentTarget.value)} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.proPicDiv}>
                                    <div className={styles.fieldName}>
                                        Profile Picture
                                    </div>
                                    <div className={styles.picUpDiv}>
                                        <div className={styles.picDiv}>
                                            <img src={PROFILEPIC} alt="Profile Picture" className={styles.picStyle} />
                                        </div>
                                        <div className={styles.upButtonsDiv}>
                                            <div className={styles.uploadButton}>
                                                <DriveFolderUploadIcon style={{ height: '30px', width: '30px', fill: "#A040AB" }} />
                                            </div>
                                            <div className={styles.deleteButton}>
                                                <DeleteOutlineIcon style={{ height: '30px', width: '30px', fill: "#A040AB" }} />
                                            </div>
                                        </div>
                                        <div className={styles.warningMsg}>
                                            Please upload image less than 5MB in size
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showReqWarn && <div className={styles.consentWarningDiv}>
                            Please fill out the required fields marked with an *
                        </div>}
                        {isGeneralFormEditable && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSaveAndContinue}>Save</div>
                        </div>)}
                    </div>
                </div>
            </>)}

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={emailExistsDialog}
                onClose={(event, reason) => closeEmailExistsDialog(event, reason)}
                hideBackdrop={true}
            >
                <div className={styles.paperUnassignML}>
                    <div className={styles.menuTitle}>
                        <div className={styles.titleText}>Warning</div>
                        <div>
                            <IconButton aria-label="delete">
                                <RiCloseCircleFill size={29} onClick={closeEmailExistsDialog} style={{ color: "#4E253A" }} />
                            </IconButton>
                        </div>
                    </div>

                    <div className={styles.patientListDiv}>
                        The email used for registration is already in use, please use a different email.
                    </div>

                    <div className={styles.menuButtons2}>
                        <div className={styles.continueButton} onClick={closeEmailExistsDialog}>
                            Continue
                        </div>
                    </div>
                </div>
            </Modal>

        </div >
    );
});

export default ProfileGeneralTab;