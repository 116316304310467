import { utilsConstants } from "../Constants/UtilConstants";

const initialState = {
    loader: false,
    patientProfilePageLoader: false,
    patientTimerDrawerLoader: false,
    patientGraphsLoader: false,
    patientAidiLoader: false,
    aidiValidationLoader: false,
    patientVitalsLoader: false,
    patientNotesLoader: false,
    assignMlLoader: false,
    thresholdsLoader: false,
    prLoader: false,
    patientQuickViewLoader: false,
    emailExistsLoader: false,
    trendsLoader: false,
    diagnosesLoader: false,
    reportsLoader: false,
    generalInfoLoader: false,
    payerInfoLoader: false,
    facilityInfoLoader: false,
    familyInfoLoader: false,
    careteamInfoLoader: false,
    reportsApplyStatus: false,
    currPatientGUID: "",
    currPatientSubjectID: "",
    allPatientsList: [],
    patientMainTab: 0,
    spo2BrExpand: false,
    hrHrvExpand: false,
    bpExpand: false,
    tempPrExpand: false,
    fevPefExpand: false,
    gwpExpand: false,
    regStatus: {
        step1: false,
        step2: false,
        step3: false,
        step4: false,
        step5: false,
        review: false,
    },
    regStep: "1",
    prGeneralInfo: {
        ehrId: '',
        prefix: '',
        firstName: '',
        middleName: '',
        suffix: '',
        lastName: '',
        prevName: '',
        dob: new Date(),
        ssn: '',
        bioSex: '',
        genderIdentity: '',
        sexOrientation: '',
        phNum: '',
        email: '',
        address: '',
        address2: '',
        city: '',
        state: '',
        zip: '',
        country: '',
    },
    prPayerInfo: {
        payerType: '',
        payerTypeOther: '',
        priPayerName: '',
        medicareBeneId: '',
        payerRel: '',
        medicaidBeneId: '',
        managedCareId: '',
        priPayerPh: '',
        edu: '',
        occupation: '',
        empStatus: '',
        religion: '',
        marStatus: '',
        ethnicity: '',
        smokeStatus: '',
        race: '',
        primLang: '',
        primCond: '',
        primProg: '',
        interpNeed: '',
        secCond: '',
        secProg: '',
        height: '',
        terCond: '',
        weight: '',
        otherCom: '',
        billPhy: '',
        billPhyId: '',
    },
    prFamilyInfo: {
        prefixC1: '',
        firstNameC1: '',
        suffixC1: '',
        lastNameC1: '',
        relC1: '',
        bioSexC1: '',
        emailC1: '',
        phNumC1: '',
        conTypeC1: [],
        comModeC1: [],

        prefixC2: '',
        firstNameC2: '',
        suffixC2: '',
        lastNameC2: '',
        relC2: '',
        bioSexC2: '',
        emailC2: '',
        phNumC2: '',
        conTypeC2: [],
        comModeC2: [],

        prefixC3: '',
        firstNameC3: '',
        suffixC3: '',
        lastNameC3: '',
        relC3: '',
        bioSexC3: '',
        emailC3: '',
        phNumC3: '',
        conTypeC3: [],
        comModeC3: [],

        consentSel: '',
    },
    prCareTeamInfo: {
        prefixC1: '',
        firstNameC1: '',
        suffixC1: '',
        lastNameC1: '',
        specC1: '',
        profC1: '',
        npiC1: '',
        emailC1: '',
        phNumC1: '',
        comModeC1: [],

        prefixC2: '',
        firstNameC2: '',
        suffixC2: '',
        lastNameC2: '',
        specC2: '',
        profC2: '',
        npiC2: '',
        emailC2: '',
        phNumC2: '',
        comModeC2: [],

        prefixC3: '',
        firstNameC3: '',
        suffixC3: '',
        lastNameC3: '',
        specC3: '',
        profC3: '',
        npiC3: '',
        emailC3: '',
        phNumC3: '',
        comModeC3: [],
    },
    prFacilityInfo: {
        facilityName: '',
        facilityNum: '',
        facilityType: '',
        bedNum: '',
        actCode: '',
        unit: '',
        floor: '',
        roomNum: '',
        admDate: new Date(),
        barcoe: '',
    },
    prSuccess: false,
    prMessage: '',
}

export function utilReducer(state = initialState, action) {

    switch (action.type) {
        case utilsConstants.SET_SHOW_LOADER:
            return {
                ...state,
                loader: action.payload
            };

        case utilsConstants.SET_CURRENT_PATIENT_GUID:
            return {
                ...state,
                currPatientGUID: action.payload
            };

        case utilsConstants.SET_CURRENT_PATIENT_SUBJECTID:
            return {
                ...state,
                currPatientSubjectID: action.payload
            };

        case utilsConstants.SET_ALL_PATIENTS_LIST:
            return {
                ...state,
                allPatientsList: action.payload
            };

        case utilsConstants.SET_SHOW_PATIENT_PROFILE_PAGE_LOADER:
            return {
                ...state,
                patientProfilePageLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_TIMER_DRAWER_LOADER:
            return {
                ...state,
                patientTimerDrawerLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_GRAPHS_LOADER:
            return {
                ...state,
                patientGraphsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_AIDI_LOADER:
            return {
                ...state,
                patientAidiLoader: action.payload
            };
        case utilsConstants.SET_SHOW_AIDI_VALIDATION_LOADER:
            return {
                ...state,
                aidiValidationLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_VITALS_LOADER:
            return {
                ...state,
                patientVitalsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PATIENT_NOTES_LOADER:
            return {
                ...state,
                patientNotesLoader: action.payload
            };
        case utilsConstants.SET_SHOW_ASSIGN_ML_LOADER:
            return {
                ...state,
                assignMlLoader: action.payload
            };
        case utilsConstants.SET_SHOW_THRESHOLDS_LOADER:
            return {
                ...state,
                thresholdsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PR_LOADER:
            return {
                ...state,
                prLoader: action.payload
            };
        case utilsConstants.SET_SHOW_QUICKVIEW_LOADER:
            return {
                ...state,
                patientQuickViewLoader: action.payload
            };
        case utilsConstants.SET_SHOW_EMAIL_EXISTS_LOADER:
            return {
                ...state,
                emailExistsLoader: action.payload
            };
        case utilsConstants.SET_PATIENT_MAINTAB:
            return {
                ...state,
                patientMainTab: action.payload
            };
        case utilsConstants.SET_SPO2_BR_EXPAND:
            return {
                ...state,
                spo2BrExpand: action.payload
            };
        case utilsConstants.SET_HR_HRV_EXPAND:
            return {
                ...state,
                hrHrvExpand: action.payload
            };
        case utilsConstants.SET_BP_EXPAND:
            return {
                ...state,
                bpExpand: action.payload
            };
        case utilsConstants.SET_TEMP_PR_EXPAND:
            return {
                ...state,
                tempPrExpand: action.payload
            };
        case utilsConstants.SET_FEV_PEF_EXPAND:
            return {
                ...state,
                fevPefExpand: action.payload
            };
        case utilsConstants.SET_G_W_P_EXPAND:
            return {
                ...state,
                gwpExpand: action.payload
            };
        case utilsConstants.SET_REGISTRATION_STATUS:
            return {
                ...state,
                regStatus: action.payload
            };
        case utilsConstants.SET_REGISTRATION_STEP:
            return {
                ...state,
                regStep: action.payload
            };
        case utilsConstants.SET_PR_GENERAL_INFO:
            return {
                ...state,
                prGeneralInfo: action.payload
            };
        case utilsConstants.SET_PR_PAYER_INFO:
            return {
                ...state,
                prPayerInfo: action.payload
            };
        case utilsConstants.SET_PR_FAMILY_INFO:
            return {
                ...state,
                prFamilyInfo: action.payload
            };
        case utilsConstants.SET_PR_CARE_TEAM_INFO:
            return {
                ...state,
                prCareTeamInfo: action.payload
            };
        case utilsConstants.SET_PR_FACILITY_INFO:
            return {
                ...state,
                prFacilityInfo: action.payload
            };
        case utilsConstants.SET_PR_SUCCESS:
            return {
                ...state,
                prSuccess: action.payload
            };
        case utilsConstants.SET_PR_MESSAGE:
            return {
                ...state,
                prMessage: action.payload
            };
        case utilsConstants.SET_SHOW_TRENDS_LOADER:
            return {
                ...state,
                trendsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_DIAGNOSES_LOADER:
            return {
                ...state,
                diagnosesLoader: action.payload
            };
        case utilsConstants.SET_SHOW_REPORTS_LOADER:
            return {
                ...state,
                reportsLoader: action.payload
            };
        case utilsConstants.SET_SHOW_GENERAL_INFO_LOADER:
            return {
                ...state,
                generalInfoLoader: action.payload
            };
        case utilsConstants.SET_SHOW_PAYER_INFO_LOADER:
            return {
                ...state,
                payerInfoLoader: action.payload
            };
        case utilsConstants.SET_SHOW_FACILITY_INFO_LOADER:
            return {
                ...state,
                facilityInfoLoader: action.payload
            };
        case utilsConstants.SET_SHOW_FAMILY_INFO_LOADER:
            return {
                ...state,
                familyInfoLoader: action.payload
            };
        case utilsConstants.SET_SHOW_CARETEAM_INFO_LOADER:
            return {
                ...state,
                careteamInfoLoader: action.payload
            };
        case utilsConstants.SET_REPORTS_APPLY_STATUS:
            return {
                ...state,
                reportsApplyStatus: action.payload
            };
        default:
            return state;
    }
}