import styles from "./FamilyInfoTab.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import { setPRFamilyInfo, setRegistrationStatus } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from "react-router-dom";
import { getSelectedProjectIdAndLocationId } from "../../../../Reducer/selectors/DashboardSelector";

const FamilyInfoTab = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const [contactsCount, setContactsCount] = useState(1);
    const [showSecContact, setShowSecContact] = useState(false);
    const [showTerContact, setShowTerContact] = useState(false);

    const [prefixC1, setPrefixC1] = useState([]);
    const [firstNameC1, setFirstNameC1] = useState('');
    const [suffixC1, setSuffixC1] = useState([]);
    const [lastNameC1, setLastNameC1] = useState('');
    const [relC1, setRelC1] = useState('');
    const [bioSexC1, setBioSexC1] = useState([]);
    const [emailC1, setEmailC1] = useState('');
    const [phNumC1, setPhNumC1] = useState('');
    const [conTypeC1, setConTypeC1] = useState([]);
    const [comModeC1, setComModeC1] = useState([]);

    const [prefixC2, setPrefixC2] = useState([]);
    const [firstNameC2, setFirstNameC2] = useState('');
    const [suffixC2, setSuffixC2] = useState([]);
    const [lastNameC2, setLastNameC2] = useState('');
    const [relC2, setRelC2] = useState('');
    const [bioSexC2, setBioSexC2] = useState([]);
    const [emailC2, setEmailC2] = useState('');
    const [phNumC2, setPhNumC2] = useState('');
    const [conTypeC2, setConTypeC2] = useState([]);
    const [comModeC2, setComModeC2] = useState([]);

    const [prefixC3, setPrefixC3] = useState([]);
    const [firstNameC3, setFirstNameC3] = useState('');
    const [suffixC3, setSuffixC3] = useState([]);
    const [lastNameC3, setLastNameC3] = useState('');
    const [relC3, setRelC3] = useState('');
    const [bioSexC3, setBioSexC3] = useState([]);
    const [emailC3, setEmailC3] = useState('');
    const [phNumC3, setPhNumC3] = useState('');
    const [conTypeC3, setConTypeC3] = useState([]);
    const [comModeC3, setComModeC3] = useState([]);

    const [consentSel, setConsentSel] = useState("No");

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isFirstNameC1Fill, setIsFirstNameC1Fill] = useState(true);
    const [isLastNameC1Fill, setIsLastNameC1Fill] = useState(true);
    const [isBioSexC1Fill, setIsBioSexC1Fill] = useState(true);
    const [isPhNumC1Fill, setIsPhNumC1Fill] = useState(true);
    const [isEmailC1Fill, setIsEmailC1Fill] = useState(true);
    const [isRelC1Fill, setIsRelC1Fill] = useState(true);
    const [isConTypeC1Fill, setIsConTypeC1Fill] = useState(true);
    const [isComModeC1Fill, setIsComModeC1Fill] = useState(true);

    const [isFirstNameC2Fill, setIsFirstNameC2Fill] = useState(true);
    const [isLastNameC2Fill, setIsLastNameC2Fill] = useState(true);
    const [isBioSexC2Fill, setIsBioSexC2Fill] = useState(true);
    const [isPhNumC2Fill, setIsPhNumC2Fill] = useState(true);
    const [isEmailC2Fill, setIsEmailC2Fill] = useState(true);
    const [isRelC2Fill, setIsRelC2Fill] = useState(true);
    const [isConTypeC2Fill, setIsConTypeC2Fill] = useState(true);
    const [isComModeC2Fill, setIsComModeC2Fill] = useState(true);

    const [isFirstNameC3Fill, setIsFirstNameC3Fill] = useState(true);
    const [isLastNameC3Fill, setIsLastNameC3Fill] = useState(true);
    const [isBioSexC3Fill, setIsBioSexC3Fill] = useState(true);
    const [isPhNumC3Fill, setIsPhNumC3Fill] = useState(true);
    const [isEmailC3Fill, setIsEmailC3Fill] = useState(true);
    const [isRelC3Fill, setIsRelC3Fill] = useState(true);
    const [isConTypeC3Fill, setIsConTypeC3Fill] = useState(true);
    const [isComModeC3Fill, setIsComModeC3Fill] = useState(true);

    const clearC1Fields = () => {
        setPrefixC1([]);
        setFirstNameC1('');
        setSuffixC1([]);
        setLastNameC1('');
        setRelC1('');
        setBioSexC1([]);
        setEmailC1('');
        setPhNumC1('');
        setConTypeC1([]);
        setComModeC1([]);
    };

    const clearC2Fields = () => {
        setPrefixC2([]);
        setFirstNameC2('');
        setSuffixC2([]);
        setLastNameC2('');
        setRelC2('');
        setBioSexC2([]);
        setEmailC2('');
        setPhNumC2('');
        setConTypeC2([]);
        setComModeC2([]);
    };

    const clearC3Fields = () => {
        setPrefixC3([]);
        setFirstNameC3('');
        setSuffixC3([]);
        setLastNameC3('');
        setRelC3('');
        setBioSexC3([]);
        setEmailC3('');
        setPhNumC3('');
        setConTypeC3([]);
        setComModeC3([]);
    };

    const clearAllFamilyFields = () => {
        clearC1Fields();
        clearC2Fields();
        clearC3Fields();
        setConsentSel("No");
        resetReqFills();
    };

    const resetC1ReqFills = () => {
        setIsFirstNameC1Fill(true);
        setIsLastNameC1Fill(true);
        setIsBioSexC1Fill(true);
        setIsPhNumC1Fill(true);
        setIsEmailC1Fill(true);
        setIsRelC1Fill(true);
        setIsConTypeC1Fill(true);
        setIsComModeC1Fill(true);
    };

    const resetC2ReqFills = () => {
        setIsFirstNameC2Fill(true);
        setIsLastNameC2Fill(true);
        setIsBioSexC2Fill(true);
        setIsPhNumC2Fill(true);
        setIsEmailC2Fill(true);
        setIsRelC2Fill(true);
        setIsConTypeC2Fill(true);
        setIsComModeC2Fill(true);
    };

    const resetC3ReqFills = () => {
        setIsFirstNameC3Fill(true);
        setIsLastNameC3Fill(true);
        setIsBioSexC3Fill(true);
        setIsPhNumC3Fill(true);
        setIsEmailC3Fill(true);
        setIsRelC3Fill(true);
        setIsConTypeC3Fill(true);
        setIsComModeC3Fill(true);
    };

    const resetReqFills = () => {
        resetC1ReqFills();
        resetC2ReqFills();
        resetC3ReqFills();
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllFamilyFields: clearAllFamilyFields,
        };
    });

    const optionsPrefix = [
        { value: 'Dr.', label: 'Dr.' },
        { value: 'Miss.', label: 'Miss.' },
        { value: 'Mr.', label: 'Mr.' },
        { value: 'Mrs.', label: 'Mrs.' },
        { value: 'Ms.', label: 'Ms.' },
    ];

    const optionsSuffix = [
        { value: 'II', label: 'II' },
        { value: 'III', label: 'III' },
        { value: 'IV', label: 'IV' },
        { value: 'Jr.', label: 'Jr.' },
        { value: 'Sr.', label: 'Sr.' },
    ];

    const optionsBioSex = [
        { value: 'Male', label: 'Male' },
        { value: 'Female', label: 'Female' },
        { value: 'Unknown', label: 'Unknown' },
    ];

    const optionsConType = [
        { value: 'Care Conference Person', label: 'Care Conference Person' },
        { value: 'Guardian of Person', label: 'Guardian of Person' },
        { value: 'Guardian of Assets', label: 'Guardian of Assets' },
        { value: 'Health Care Surrogate', label: 'Health Care Surrogate' },
        { value: 'POA-Financial', label: 'POA-Financial' },
        { value: 'POA-Care', label: 'POA-Care' },
        { value: 'This contact receives satisfaction survey', label: 'This contact receives satisfaction survey' },
        { value: 'Resident makes health care decisions', label: 'Resident makes health care decisions' },
        { value: 'Financial Responsible Party', label: 'Financial Responsible Party' },
    ];

    const optionsComModeC1 = [
        { value: 'Email', label: 'Email' },
        { value: 'Phone', label: 'Phone' },
        { value: 'Both', label: 'Both' },
    ];

    const customContentRendererMulti = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            return (
                <div>
                    Selected <b>{state.values.length}</b> out of{" "}
                    <b>{props.options.length}</b>
                </div>
            );
        } else {
            return (
                <div></div>
            );
        }
    };

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const onAddNewContact = () => {
        if (contactsCount < 3) {
            let temp = contactsCount + 1;
            setContactsCount(temp);
        } else {
            setContactsCount(1);
        }
    };

    const onAddSecContact = (event) => {
        event.preventDefault();
        if (firstNameC1 !== '') {
            setShowSecContact(true);
            setContactsCount(2);
        }
    };

    const onAddTerContact = (event) => {
        event.preventDefault();
        if (firstNameC2 !== '') {
            setShowTerContact(true);
            setContactsCount(3);
        }
    };

    const deleteContact = () => {
        if (contactsCount === 2) {
            clearC2Fields();
            resetC2ReqFills();
            setContactsCount(1);
        } else if (contactsCount === 3) {
            clearC3Fields();
            resetC3ReqFills();
            setContactsCount(2);
        } else {
            let temp = contactsCount - 1;
            setContactsCount(temp);
        }
    };

    const deleteSecContact = (event) => {
        event.preventDefault();
        setShowSecContact(false);
        clearC2Fields();
        resetC2ReqFills();
        setContactsCount(1);
    };

    const deleteTerContact = (event) => {
        event.preventDefault();
        setShowTerContact(false);
        clearC3Fields();
        resetC3ReqFills();
        setContactsCount(2);
    };

    const getStringFromSelectArray = (arr) => {
        const len = arr.length;
        let res = "";
        for (let i = 0; i < len; i++) {
            if (res.length > 0) {
                res = res + ", " + arr[i].value;
            } else {
                res = res + arr[i].value;
            }
        }
        return res;
    };

    const checkIfContact1IsDone = () => {
        return true;
        if ((firstNameC1 !== '') && (lastNameC1 !== '') && (relC1 !== '') && (bioSexC1[0] && bioSexC1[0].value !== '') && (emailC1 !== '') && (phNumC1 !== '') && (conTypeC1[0] && conTypeC1[0].value !== '') && (comModeC1[0] && comModeC1[0].value !== '')) {
            resetC1ReqFills();
            return true;
        } else {
            resetC1ReqFills();
            if (firstNameC1 === '') {
                setIsFirstNameC1Fill(false);
            };
            if (lastNameC1 === '') {
                setIsLastNameC1Fill(false);
            };
            if (relC1 === '') {
                setIsRelC1Fill(false);
            };
            if (emailC1 === '') {
                setIsEmailC1Fill(false);
            };
            if (phNumC1 === '') {
                setIsPhNumC1Fill(false);
            };
            if (!bioSexC1[0] || bioSexC1[0].value === '') {
                setIsBioSexC1Fill(false);
            };
            if (!conTypeC1[0] || conTypeC1[0].value === '') {
                setIsConTypeC1Fill(false);
            };
            if (!comModeC1[0] || comModeC1[0].value === '') {
                setIsComModeC1Fill(false);
            };
            return false;
        }
    };

    const checkIfContact2IsDone = () => {
        return true;
        if (contactsCount > 1) {
            if ((firstNameC2 !== '') && (lastNameC2 !== '') && (relC2 !== '') && (bioSexC2[0] && bioSexC2[0].value !== '') && (emailC2 !== '') && (phNumC2 !== '') && (conTypeC2[0] && conTypeC2[0].value !== '') && (comModeC2[0] && comModeC2[0].value !== '')) {
                resetC2ReqFills();
                return true;
            } else {
                resetC2ReqFills();
                if (firstNameC2 === '') {
                    setIsFirstNameC2Fill(false);
                };
                if (lastNameC2 === '') {
                    setIsLastNameC2Fill(false);
                };
                if (relC2 === '') {
                    setIsRelC2Fill(false);
                };
                if (emailC2 === '') {
                    setIsEmailC2Fill(false);
                };
                if (phNumC2 === '') {
                    setIsPhNumC2Fill(false);
                };
                if (!bioSexC2[0] || bioSexC2[0].value === '') {
                    setIsBioSexC2Fill(false);
                };
                if (!conTypeC2[0] || conTypeC2[0].value === '') {
                    setIsConTypeC2Fill(false);
                };
                if (!comModeC2[0] || comModeC2[0].value === '') {
                    setIsComModeC2Fill(false);
                };
                return false;
            }
        } else {
            return true;
        }
    };

    const checkIfContact3IsDone = () => {
        return true;
        if (contactsCount > 2) {
            if ((firstNameC3 !== '') && (lastNameC3 !== '') && (relC3 !== '') && (bioSexC3[0] && bioSexC3[0].value !== '') && (emailC3 !== '') && (phNumC3 !== '') && (conTypeC3[0] && conTypeC3[0].value !== '') && (comModeC3[0] && comModeC3[0].value !== '')) {
                resetC3ReqFills();
                return true;
            } else {
                resetC3ReqFills();
                if (firstNameC3 === '') {
                    setIsFirstNameC3Fill(false);
                };
                if (lastNameC3 === '') {
                    setIsLastNameC3Fill(false);
                };
                if (relC3 === '') {
                    setIsRelC3Fill(false);
                };
                if (emailC3 === '') {
                    setIsEmailC3Fill(false);
                };
                if (phNumC3 === '') {
                    setIsPhNumC3Fill(false);
                };
                if (!bioSexC3[0] || bioSexC3[0].value === '') {
                    setIsBioSexC3Fill(false);
                };
                if (!conTypeC3[0] || conTypeC3[0].value === '') {
                    setIsConTypeC3Fill(false);
                };
                if (!comModeC3[0] || comModeC3[0].value === '') {
                    setIsComModeC3Fill(false);
                };
                return false;
            }
        } else {
            return true;
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: true,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = () => {

        const payload = {
            prefixC1: prefixC1[0] ? prefixC1[0].value : '',
            firstNameC1: firstNameC1,
            suffixC1: suffixC1[0] ? suffixC1[0].value : '',
            lastNameC1: lastNameC1,
            relC1: relC1,
            bioSexC1: bioSexC1[0] ? bioSexC1[0].value : '',
            emailC1: emailC1,
            phNumC1: phNumC1,
            conTypeC1: getStringFromSelectArray(conTypeC1),
            comModeC1: getStringFromSelectArray(comModeC1),

            prefixC2: prefixC2[0] ? prefixC2[0].value : '',
            firstNameC2: firstNameC2,
            suffixC2: suffixC2[0] ? suffixC2[0].value : '',
            lastNameC2: lastNameC2,
            relC2: relC2,
            bioSexC2: bioSexC2[0] ? bioSexC2[0].value : '',
            emailC2: emailC2,
            phNumC2: phNumC2,
            conTypeC2: getStringFromSelectArray(conTypeC2),
            comModeC2: getStringFromSelectArray(comModeC2),

            prefixC3: prefixC3[0] ? prefixC3[0].value : '',
            firstNameC3: firstNameC3,
            suffixC3: suffixC3[0] ? suffixC3[0].value : '',
            lastNameC3: lastNameC3,
            relC3: relC3,
            bioSexC3: bioSexC3[0] ? bioSexC3[0].value : '',
            emailC3: emailC3,
            phNumC3: phNumC3,
            conTypeC3: getStringFromSelectArray(conTypeC3),
            comModeC3: getStringFromSelectArray(comModeC3),

            consentSel: consentSel,
        };
        console.log("familyInfoTab payload: ", payload);
        dispatch(setPRFamilyInfo(payload));
    };

    const checkIfPageConditionsMet = () => {
        if (checkIfContact1IsDone() && checkIfContact2IsDone() && checkIfContact3IsDone()) {
            console.log('conditions met!');
            setShowReqWarn(false);
            return true;
        } else {
            console.log('conditions not met!');
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSaveAndContinue = () => {
        if (checkIfPageConditionsMet()) {
            props.tabChange("4");
            setTabInfo();
            markStepCompleted();
        }
    };

    const navigate = useNavigate();
    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const handleBackCLick = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: false,
            step3: false,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("2");

        //navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const onValueChange = (event) => {
        // Updating the state with the selected radio button's value
        setConsentSel(event.target.value);
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    return (
        <>
            <div className={styles.containerDiv}>
                <div className={styles.dumDiv}>

                    {contactsCount >= 1 && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Primary </div>
                            <div className={styles.dummyDiv} > </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC1}
                                                onChange={setPrefixC1}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC1} onChange={(e) => setFirstNameC1(e.currentTarget.value)} className={isFirstNameC1Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isBioSexC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={bioSexC1}
                                            onChange={setBioSexC1}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC1} onChange={(e) => setLastNameC1(e.currentTarget.value)} className={isLastNameC1Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC1}
                                                onChange={setSuffixC1}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={relC1} onChange={(e) => setRelC1(e.currentTarget.value)} className={isRelC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC1} onChange={(e) => setEmailC1(e.currentTarget.value)} className={isEmailC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Contact Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isConTypeC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={conTypeC1}
                                            onChange={setConTypeC1}
                                            options={optionsConType}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC1} onChange={(e) => setPhNumC1(e.currentTarget.value)} className={isPhNumC1Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            multi
                                            contentRenderer={customContentRendererMulti}
                                            placeholder=""
                                            className={isComModeC1Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC1}
                                            onChange={setComModeC1}
                                            options={optionsComModeC1}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(!showSecContact && !showTerContact) && (<>
                            <div className={styles.divFooter}>
                                <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddSecContact(event)}>
                                        + Add Another Contact
                                    </div>
                                </div>
                                <div className={styles.consentDiv}>
                                    <div className={styles.consentLabel}>Consent</div>
                                    <div className={styles.consentConent}>
                                        Allow staff involved in my care to get access to my medical records from my prior caregivers, and to share my medical records with other providers who can assist my current or future care.
                                    </div>
                                    <div className={styles.consentButtons}>
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={consentSel === "No"}
                                                onChange={onValueChange}
                                            />
                                            No
                                        </label>

                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={consentSel === "Yes"}
                                                onChange={onValueChange}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>}
                                <div className={styles.pageButtonsDiv}>
                                    <div className={styles.cancelButton} onClick={handleBackCLick}>Back</div>
                                    <div className={styles.saveButton} onClick={handleSaveAndContinue}>Save & Continue</div>
                                </div>
                            </div>
                        </>)}
                    </>)}


                    {showSecContact && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Secondary </div>
                            <div className={styles.deleteButton1}> {!showTerContact && (<CloseIcon onClick={(event) => deleteSecContact(event)} style={{ height: '20px', width: '20px', fill: "#A040AB" }} />)} </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC2}
                                                onChange={setPrefixC2}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC2} onChange={(e) => setFirstNameC2(e.currentTarget.value)} className={isFirstNameC2Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isBioSexC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={bioSexC2}
                                            onChange={setBioSexC2}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC2} onChange={(e) => setLastNameC2(e.currentTarget.value)} className={isLastNameC2Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC2}
                                                onChange={setSuffixC2}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={relC2} onChange={(e) => setRelC2(e.currentTarget.value)} className={isRelC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC2} onChange={(e) => setEmailC2(e.currentTarget.value)} className={isEmailC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Contact Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isConTypeC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={conTypeC2}
                                            onChange={setConTypeC2}
                                            options={optionsConType}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC2} onChange={(e) => setPhNumC2(e.currentTarget.value)} className={isPhNumC2Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            multi
                                            contentRenderer={customContentRendererMulti}
                                            placeholder=""
                                            className={isComModeC2Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC2}
                                            onChange={setComModeC2}
                                            options={optionsComModeC1}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {(showSecContact && !showTerContact) && (<>
                            <div className={styles.divFooter}>
                                <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={(event) => onAddTerContact(event)}>
                                        + Add Another Contact
                                    </div>
                                </div>
                                <div className={styles.consentDiv}>
                                    <div className={styles.consentLabel}>Consent</div>
                                    <div className={styles.consentConent}>
                                        Allow staff involved in my care to get access to my medical records from my prior caregivers, and to share my medical records with other providers who can assist my current or future care.
                                    </div>
                                    <div className={styles.consentButtons}>
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={consentSel === "No"}
                                                onChange={onValueChange}
                                            />
                                            No
                                        </label>

                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={consentSel === "Yes"}
                                                onChange={onValueChange}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>}
                                <div className={styles.pageButtonsDiv}>
                                    <div className={styles.cancelButton} onClick={handleBackCLick}>Back</div>
                                    <div className={styles.saveButton} onClick={handleSaveAndContinue}>Save & Continue</div>
                                </div>
                            </div>
                        </>)}
                    </>)}


                    {showTerContact && (<>
                        <div className={styles.headerDiv}>
                            <div className={styles.dummyDiv}></div>
                            <div className={styles.headerTitle}> Tertiary </div>
                            <div className={styles.deleteButton1}> <CloseIcon style={{ height: '20px', width: '20px', fill: "#A040AB" }} onClick={(event) => deleteTerContact(event)} /> </div>
                        </div>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Prefix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={prefixC3}
                                                onChange={setPrefixC3}
                                                options={optionsPrefix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>First Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={firstNameC3} onChange={(e) => setFirstNameC3(e.currentTarget.value)} className={isFirstNameC3Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isBioSexC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={bioSexC3}
                                            onChange={setBioSexC3}
                                            options={optionsBioSex}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv2}>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Last Name</div>
                                        <div className={styles.subFieldInput}>
                                            <input type="text" value={lastNameC3} onChange={(e) => setLastNameC3(e.currentTarget.value)} className={isLastNameC3Fill ? styles.inputFieldSelcted2 : styles.inputFieldSelctedReq2} onFocus={(e) => handleFocus(e)} />
                                        </div>
                                    </div>
                                    <div className={styles.subFieldDiv}>
                                        <div className={styles.subFieldName}>Suffix</div>
                                        <div className={styles.subFieldInput}>
                                            <Select
                                                contentRenderer={customContentRenderer}
                                                placeholder=""
                                                className={styles.dropdown}
                                                values={suffixC3}
                                                onChange={setSuffixC3}
                                                options={optionsSuffix}
                                                color="#4E253A"
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={relC3} onChange={(e) => setRelC3(e.currentTarget.value)} className={isRelC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Email Address
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={emailC3} onChange={(e) => setEmailC3(e.currentTarget.value)} className={isEmailC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Contact Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            placeholder=""
                                            className={isConTypeC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={conTypeC3}
                                            onChange={setConTypeC3}
                                            options={optionsConType}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={phNumC3} onChange={(e) => setPhNumC3(e.currentTarget.value)} className={isPhNumC3Fill ? styles.inputFieldSelcted : styles.inputFieldSelctedReq} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of communication
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            multi
                                            contentRenderer={customContentRendererMulti}
                                            placeholder=""
                                            className={isComModeC3Fill ? styles.dropdown2 : styles.dropdownReq2}
                                            values={comModeC3}
                                            onChange={setComModeC3}
                                            options={optionsComModeC1}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {showTerContact && (<>
                            <div className={styles.divFooter}>
                                {/* <div className={styles.addNewDiv}>
                                    <div className={styles.addNewContButton} onClick={onAddNewContact}>
                                        + Add Another Contact
                                    </div>
                                </div> */}
                                <div className={styles.consentDiv}>
                                    <div className={styles.consentLabel}>Consent</div>
                                    <div className={styles.consentConent}>
                                        Allow staff involved in my care to get access to my medical records from my prior caregivers, and to share my medical records with other providers who can assist my current or future care.
                                    </div>
                                    <div className={styles.consentButtons}>
                                        <label>
                                            <input
                                                type="radio"
                                                value="No"
                                                checked={consentSel === "No"}
                                                onChange={onValueChange}
                                            />
                                            No
                                        </label>

                                        <label>
                                            <input
                                                type="radio"
                                                value="Yes"
                                                checked={consentSel === "Yes"}
                                                onChange={onValueChange}
                                            />
                                            Yes
                                        </label>
                                    </div>
                                </div>
                                {showReqWarn && <div className={styles.consentWarningDiv}>
                                    Please fill out the required fields marked with an *
                                </div>}
                                <div className={styles.pageButtonsDiv}>
                                    <div className={styles.cancelButton} onClick={handleBackCLick}>Back</div>
                                    <div className={styles.saveButton} onClick={handleSaveAndContinue}>Save & Continue</div>
                                </div>
                            </div>
                        </>)}
                    </>)}

                </div>
            </div>
        </>
    );
});

export default FamilyInfoTab;