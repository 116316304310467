
import styles from "./DiagnosesTable.module.css";
import Grid from "@material-ui/core/Grid";
import { FaSort } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { getDiagnosesLoader } from "../../../../Reducer/selectors/UtilSelector";
import { getAvailableDiagnosisRankSelector, getPatientDiagnoses, setAdmissionDateSelector } from "../../../../Reducer/selectors/PatientSelector";
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import CloseIcon from '@mui/icons-material/Close';
import Modal from "@material-ui/core/Modal";
import Backdrop from '@mui/material/Backdrop';
import Input from '@material-ui/core/Input';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Popper from "@mui/material/Popper";
import PopupState, { bindToggle, bindPopper } from "material-ui-popup-state";
import Radio from '@mui/material/Radio';
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getSelectedSubjectId, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import { getAvailableDiagnosisRanks, getDiagnosesPayload, updateDiagnosis } from "../../../../Actions";
import Select from 'react-dropdown-select';
import Checkbox from '@mui/material/Checkbox';
import { Calendar } from "react-date-range";
import { addDays, format } from "date-fns";
import { setShowDiagnosesLoader } from "../../../../Actions/UtilActions";

export default function DiagnosesTable(props) {

    const dispatch = useDispatch();

    const patientDiagnosesLoader = useSelector(getDiagnosesLoader);
    const [diagnosesListSorting, setDiagnosesListSorting] = useState([]);
    const [sortDirection, setSortDirection] = useState(false);
    const patientDiagnoses = useSelector(getPatientDiagnoses);
    //console.log("Diagnoses: ", patientDiagnoses);

    const [searched, setSearched] = useState("");

    const [isEdit, setIsEdit] = useState(false);

    const [selectedProgram, setSelectedProgram] = useState("");
    const [selectedStatus, setSelectedStatus] = useState("Active");

    const [showEditDx, setShowEditDx] = useState(false);
    const subjectId = useSelector(getSelectedSubjectId);

    const [selectedCode, setSelectedCode] = useState("");
    const [selectedDesc, setSelectedDesc] = useState("");
    const [program, setProgram] = useState([]);
    const [rank, setRank] = useState([]);
    const [classification, setClassification] = useState([]);
    const [therapy, setTherapy] = useState();
    const [confidential, setConfidential] = useState();
    const [onset, setOnset] = useState([]);
    const [onsetDate, setOnsetDate] = useState(new Date());
    console.log('onsetDate: ', onsetDate);
    const [status, setStatus] = useState([]);
    const [resolvedDate, setResolvedDate] = useState(new Date());
    const [comment, setComment] = useState("");

    const [diagObj, setDiagObj] = useState();
    const [selectedDiagId, setSelectedDiagId] = useState();

    const userProfile = useSelector(getUserProfile);

    const [optionsProgram, setOptionsProgram] = useState([
        { value: 'RPM', label: 'RPM' },
        { value: 'CCM', label: 'CCM' },
        { value: 'Others', label: 'Others' },
        { value: 'None', label: 'None' },
    ]);

    const optionsRank = useSelector(getAvailableDiagnosisRankSelector);

    const [optionsClassification, setOptionsClassification] = useState([
        { value: 'Admission', label: 'Admission' },
        { value: 'Discharge', label: 'Discharge' },
        { value: 'During stay', label: 'During stay' },
        { value: 'History', label: 'History' },
        { value: 'Admitting Dx', label: 'Admitting Dx' },
    ]);

    const [optionsOnset, setOptionsOnset] = useState([
        { value: 'Choose Date', label: 'Choose Date' },
        { value: 'Admission Date', label: 'Admission Date' },
        { value: 'Unknown', label: 'Unknown' },
    ]);

    const [optionsStatus, setOptionsStatus] = useState([
        { value: 'Active', label: 'Active' },
        { value: 'Resolved', label: 'Resolved' },
        { value: 'Strikeout', label: 'Strikeout' },
    ]);

    const [isCalOpen, setIsCalOpen] = useState(false);
    const [isCalOpen1, setIsCalOpen1] = useState(false);

    const [isOnsetDateDisabled, setIsOnsetDateDisabled] = useState();
    const [isResolvedDateDisabled, setIsResolvedDateDisabled] = useState();

    const [errMsg, setErrMsg] = useState("");

    const admissionDate = useSelector(setAdmissionDateSelector);

    // useEffect(() => {
    //     if (onset && onset[0] && onset[0].value === "Choose Date") {
    //         setIsOnsetDateDisabled(false);
    //         setOnsetDate(new Date());
    //     } else if (onset && onset[0] && onset[0].value === "Admission Date") {
    //         setOnsetDate(new Date(admissionDate));
    //         setIsOnsetDateDisabled(false);
    //     } else if (onset && onset[0] && onset[0].value === "Unknown") {
    //         setOnsetDate(new Date());
    //         setIsOnsetDateDisabled(true);
    //         setIsCalOpen(false);
    //     }
    // }, [onset]);

    // useEffect(() => {
    //     console.log(status);
    //     if (status && status[0] && status[0].value === "Active") {
    //         setIsResolvedDateDisabled(true);
    //     } else if (status && status[0] && status[0].value === "Resolved") {
    //         setResolvedDate(new Date());
    //         setIsResolvedDateDisabled(false);
    //     } else if (status && status[0] && status[0].value === "Strikeout") {
    //         setResolvedDate(new Date());
    //         setIsResolvedDateDisabled(false);
    //     }
    // }, [status]);

    const handleSettingOnsetDate = (str, val) => {
        // console.log(val);
        // console.log(new Date(val));
        if (str === "Choose Date") {
            setOnsetDate(new Date(val));
            setIsOnsetDateDisabled(false);
        } else if (str === "Admission Date") {
            setOnsetDate(new Date(val));
            setIsOnsetDateDisabled(false);
        } else if (str === "Unknown") {
            setOnsetDate(new Date());
            setIsOnsetDateDisabled(true);
            setIsCalOpen(false);
        }
    };

    const handleSettingResolvedDate = (str, val) => {
        if (str === "Active") {
            setIsResolvedDateDisabled(true);
        } else if (str === "Resolved") {
            setResolvedDate(new Date(val));
            setIsResolvedDateDisabled(false);
        } else if (str === "Strikeout") {
            setResolvedDate(new Date(val));
            setIsResolvedDateDisabled(false);
        }
    };

    const capitalizeFirstLetter = (str) => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const setAllFields = (obj) => {
        setDiagObj(obj);
        setSelectedDiagId(obj.id);
        setSelectedCode(obj.icdCode);
        setSelectedDesc(obj.description);
        setProgram([{ value: obj.program, label: obj.program }]);
        setRank([{ value: obj.rank, label: obj.rank }]);
        setClassification([{ value: obj.classification, label: obj.classification }]);
        handleTherapyCheckboxChange(obj.therapy);
        handleConfidentialCheckboxChange(obj.confidential);
        setOnset([{ value: obj.onset, label: obj.onset }]);
        handleSettingOnsetDate(obj.onset, obj.onsetDate);
        setStatus([{ value: capitalizeFirstLetter(obj.status), label: capitalizeFirstLetter(obj.status) }]);
        handleSettingResolvedDate(capitalizeFirstLetter(obj.status), obj.resolvedDate);
        setComment(obj.comment);
    };

    useEffect(() => {
        setDiagnosesListSorting(patientDiagnoses);
    }, [patientDiagnoses]);

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(12),
            border: '1px solid #dadde9',
        },
    }));

    const openEditDx = (event, diagObj) => {
        console.log("openEditDx");
        event.preventDefault();
        setAllFields(diagObj);
        let payload = {
            subjectId: subjectId.selectedSubjectId,
        };
        dispatch(getAvailableDiagnosisRanks(payload));
        setShowEditDx(true);
    };

    const closeEditDxDialog = (event) => {
        event.preventDefault();
        setShowEditDx(false);
    };

    const diagnosesTableBody = diagnosesListSorting.map((diag) => {
        return (
            <div>
                <table className={styles.responsive1}>
                    <tbody>
                        <tr>
                            <td className={styles.tableCellContentsCode}><div className={styles.tableCellContainer}>{diag.icdCode}</div></td>
                            <td className={styles.tableCellContentsDesc}>
                                <HtmlTooltip
                                    title={
                                        <React.Fragment>
                                            {diag.description}
                                        </React.Fragment>
                                    }
                                >
                                    <div className={styles.tableCellContainer}>{diag.description.length > 50 ? diag.description.substring(0, 50) + "..." : diag.description}</div>
                                </HtmlTooltip>
                            </td>
                            <td className={styles.tableCellContentsRank}><div className={styles.tableCellContainer}>{diag.rank}</div></td>
                            <td className={styles.tableCellContentsClass}><div className={styles.tableCellContainer}>{diag.classification}</div></td>
                            <td className={styles.tableCellContentsOnsetDate}><div className={styles.tableCellContainer}>{diag.onset === "Unknown" ? "Unknown" : diag.onsetDate}</div></td>
                            <td className={styles.tableCellContentsCB}><div className={styles.tableCellContainer}>{diag.createdBy}</div></td>
                            <td className={styles.tableCellContentsIcon}><div className={styles.tableCellContainer} onClick={e => openEditDx(e, diag)}><RemoveRedEyeOutlinedIcon sx={{ color: "#4E253A" }} /></div></td>
                        </tr>
                    </tbody>
                </table>
            </div>)
    });


    useEffect(() => {
        let filterdDiagnosesList = applySelectedFilters(patientDiagnoses);
        setDiagnosesListSorting(filterdDiagnosesList);
    }, [patientDiagnoses, selectedProgram, selectedStatus]);


    const applySelectedFilters = (diagnosesList) => {

        let filteredList = diagnosesList.filter((diagnosis) => {
            if (selectedProgram === "" && selectedStatus === "") {
                return true;
            } else if (diagnosis.program.toLowerCase() === selectedProgram.toLowerCase() && diagnosis.status.toLowerCase() === selectedStatus.toLowerCase()) {
                return true;
            } else if (diagnosis.program.toLowerCase() === selectedProgram.toLowerCase() && selectedStatus === "") {
                return true;
            } else if (selectedProgram.toLowerCase() === "" && selectedStatus.toLowerCase() === "") {
                return true;
            } else if (selectedProgram.toLowerCase() === "" && diagnosis.status.toLowerCase() === selectedStatus.toLowerCase()) {
                return true;
            }
        });

        return filteredList;
    };

    const handleStringSorting = (key) => {
        setSortDirection((prevSortDirection) => !prevSortDirection);
        let direction = sortDirection;
        let sortableItem = [...diagnosesListSorting];
        sortableItem.sort((a, b) => {
            if (direction === false) {
                return a[key]?.localeCompare(b[key]);
            } else {
                return b[key]?.localeCompare(a[key]);
            }
        });
        return setDiagnosesListSorting(sortableItem);
    };

    const requestSearch = (event) => {
        event.preventDefault();
        setSearched(event.target.value);
        let searchVal = searched;

        if (!searchVal || searchVal.length === 0) {
            const patientsDiagnosesListCopy = patientDiagnoses.map((x) => x);
            setDiagnosesListSorting(patientsDiagnosesListCopy);
        } else {
            let filteredPatientDiagnoses = [];
            const patientsDiagnosesListCopy = patientDiagnoses.map((x) => x);
            filteredPatientDiagnoses = patientsDiagnosesListCopy.filter((row) => {

                return (
                    (row.icdCode && row.icdCode.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
                    (row.description && row.description.toString().toLowerCase().includes(searchVal)) ||
                    (row.rank && row.rank.toString().toLowerCase().includes(searchVal.toLowerCase())) ||
                    (row.classification && row.classification.toString().toLowerCase().includes(searchVal)));
            });
            setDiagnosesListSorting(filteredPatientDiagnoses);
        }
    };

    const resetSearch = () => {
        setSearched("");
        const patientsDiagnosesListCopy = patientDiagnoses.map((x) => x);
        setDiagnosesListSorting(patientsDiagnosesListCopy);
    };

    const addToStatusFilter = (event) => {
        event.preventDefault();
        setSelectedStatus(event.target.name);
    };

    const addToProgramFilter = (event) => {
        event.preventDefault();
        setSelectedProgram(event.target.name);
    };

    const clearProgramFilter = () => {
        setSelectedProgram("");
    };

    const clearStatusFilter = () => {
        setSelectedStatus("");
    };

    const RenderProgramFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedProgram === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedProgram === filter.toString()} onClick={(event) => addToProgramFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const RenderStatusFilters = (props) => {
        return (
            <FormGroup>
                {props.filterList && props.filterList.map(filter =>
                    <FormControlLabel className={selectedStatus === filter.toString() ? styles.filtersDivTextSelected : styles.filtersDivText} control={<Radio size="small" sx={{ color: "#AA346F", fontSize: "14px !important", '&.Mui-checked': { color: "#AA346F" } }} name={filter.toString()} checked={selectedStatus === filter.toString()} onClick={(event) => addToStatusFilter(event)} />} label={<span style={{ fontSize: '14px', fontFamily: "GTWalsheimProRegular" }}>{filter.toString()}</span>} />)}
            </FormGroup>
        );
    };

    const [programFilters, setProgramFilters] = useState(["RPM", "CCM", "Others", "None"]);

    const ProgramFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderProgramFilters filterList={programFilters}></RenderProgramFilters>
            </div>
        );
    };

    const [statusFilters, setStatusFilters] = useState(["Active", "Resolved", "Strikeout"]);

    const StatusFilterPanel = (props) => {
        return (
            <div className={styles.wrapper}>
                <RenderStatusFilters filterList={statusFilters}></RenderStatusFilters>
            </div>
        );
    };

    const handleFocus = (event) => event.target.select();


    const customContentRenderer = ({ props, state }) => {
        if (state && state.values && state.values.length > 0) {
            if (state.values[0] && state.values[0].label && state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const handleTherapyCheckboxChange = (val) => {
        setTherapy(val);
    };

    const handleConfidentialCheckboxChange = (val) => {
        setConfidential(val);
    };

    const handleCalClickCancel = (event) => {
        setIsCalOpen(false);
    };

    const handleCalClickCancel1 = (event) => {
        setIsCalOpen1(false);
    };

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else if (!isOnsetDateDisabled && isEdit && (onset[0] && onset[0].value !== 'Admission Date')) {
            setIsCalOpen(true);
        }
    };

    const handleCalClick1 = (event) => {
        if (isCalOpen1) {
            setIsCalOpen1(false);
        } else if (!isResolvedDateDisabled && isEdit) {
            setIsCalOpen1(true);
        }
    };

    const checkOnsetConditions = () => {
        if (onset && onset[0] && onset[0].value) {
            return true;
        } else {
            return false;
        }
    };

    const checkStatusConditions = () => {
        if (status && status[0] && status[0].value) {
            return true;
        } else {
            return false;
        }
    };

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const checkIfConditionsAreMet = async () => {
        if (selectedCode !== "Search and select" && selectedDesc !== "Search and select" && (program && program[0] && program[0].value !== "") && checkOnsetConditions && checkStatusConditions) {
            console.log('conditions are met!');
            let payload = {
                onset: onset[0].value,
                onsetDate: onset[0].value !== 'Unknown' ? `${format(onsetDate, "yyyy-MM-dd")}` : null,
                program: program[0].value.trim().toUpperCase(),
                rankDesc: rank ? rank[0] ? rank[0].value : '' : '',
                therapy: therapy,
                classification: classification ? classification[0] ? classification[0].value : '' : '',
                createdBy: userProfile.firstName + ' ' + userProfile.lastName,
                confidential: confidential,
                status: status ? status[0] ? status[0].value.trim().toUpperCase() : '' : '',
                comment: comment,
                resolvedDate: status[0].value !== 'Active' ? `${format(resolvedDate, "yyyy-MM-dd")}` : null,
                subjId: subjectId.selectedSubjectId,
                id: selectedDiagId,
            }
            console.log(payload);
            dispatch(updateDiagnosis(payload));
            dispatch(setShowDiagnosesLoader(true));
            setIsEdit(false);
            setShowEditDx(false);
            await sleep(5000);
            dispatch(getDiagnosesPayload({ subjectId: subjectId.selectedSubjectId }));
        } else {
            console.log('conditions are not met!');
            setErrMsg("Please fill all the required fields marked *");
        }
    };

    const handleEditButton = (event) => {
        event.preventDefault();
        setIsEdit(true);
    };

    const handleCancelButton = (event) => {
        event.preventDefault();
        setShowEditDx(false);
    };

    const handleCancelButton1 = (event) => {
        event.preventDefault();
        setAllFields(diagObj);
        setIsEdit(false);
    };

    const handleSaveButton = (event) => {
        event.preventDefault();
        checkIfConditionsAreMet();
    };

    return (

        <div className={styles.tableContainer}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={showEditDx}
            ></Backdrop>
            <div className={styles.topDiv}>
                <Input
                    className={styles.searchBar}
                    type="text"
                    placeholder="Search"
                    value={searched}
                    onChange={(event) => setSearched(event.target.value)}
                    onKeyUp={(event) => requestSearch(event)}
                    endAdornment={searched ?
                        <><InputAdornment position="start">
                            <IconButton
                                className={styles.cancelButWrapper}
                            >
                                <CloseIcon onClick={() => resetSearch()} style={{ color: "#4E253A", height: '15px', width: '15px' }}></CloseIcon>
                            </IconButton>
                        </InputAdornment> </> :
                        <></>
                    }
                    startAdornment={
                        <InputAdornment position="end">
                            {<IconButton
                                sx={{ color: "#4E253A" }}
                            >
                                {<SearchIcon style={{ color: "#4E253A" }}></SearchIcon>}
                            </IconButton>}
                        </InputAdornment>
                    }
                    disableUnderline={true}
                    style={{
                        fontFamily: "GTWalsheimProRegular"
                    }}
                />

                <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                    {(popupState) => (
                        <div className={selectedProgram != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                            <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                            <div className={styles.filterDivLabel} >{selectedProgram != "" ? selectedProgram : 'Program'}</div>
                            <div>
                                {selectedProgram != "" ? (<CloseIcon onClick={() => clearProgramFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                            </div>
                            <Popper {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper className={styles.filterBackdrop}>
                                            <ProgramFilterPanel popupState={popupState} />
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    )}
                </PopupState>

                <PopupState variant="popper" popupId="demo-popup-popper" placement="bottom-start">
                    {(popupState) => (
                        <div className={selectedStatus != "" ? styles.filterDivSelected : styles.filterDiv} {...bindToggle(popupState)}>
                            <Radio size="small" sx={{ color: "#AA346F", '&.Mui-checked': { color: "#AA346F" } }} checked={true}></Radio>
                            <div className={styles.filterDivLabel} >{selectedStatus != "" ? selectedStatus : 'Status'}</div>
                            <div>
                                {selectedStatus != "" ? (<CloseIcon onClick={() => clearStatusFilter()} style={{ marginTop: '6px', color: "#4E253A", height: "16px", width: "16px" }}></CloseIcon>) : (<ExpandMoreIcon style={{ marginTop: '6px', color: "#4E253A", height: "20px", width: "20px" }}></ExpandMoreIcon>)}
                            </div>
                            <Popper {...bindPopper(popupState)} transition>
                                {({ TransitionProps }) => (
                                    <Fade {...TransitionProps} timeout={350}>
                                        <Paper className={styles.filterBackdrop}>
                                            <StatusFilterPanel popupState={popupState} />
                                        </Paper>
                                    </Fade>
                                )}
                            </Popper>
                        </div>
                    )}
                </PopupState>
            </div>
            <div className={styles.tableDiv}>
                <table className={styles.responsive}>
                    <thead>
                        <tr>
                            <th className={styles.codeHeader} onClick={() => handleStringSorting("icdCode")}>
                                <div className={styles.headingContainer1}>
                                    Code
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.descHeader} onClick={() => handleStringSorting("description")}>
                                <div className={styles.headingContainer1}>
                                    Description
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.classHeader}>
                                <div className={styles.headingContainer1}>
                                    Rank
                                    {/* <FaSort className={styles.iconsStyleSort} /> */}
                                </div>
                            </th>
                            <th className={styles.cdHeader} onClick={() => handleStringSorting("classification")}>
                                <div className={styles.headingContainer1}>
                                    Classification
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.cdHeader}>
                                <div className={styles.headingContainer1}>
                                    Onset Date
                                    <HtmlTooltip
                                        title={
                                            <React.Fragment>
                                                YYYY-MM-DD
                                            </React.Fragment>
                                        }
                                    >
                                        <InfoOutlinedIcon className={styles.iconsStyleSort1} />
                                    </HtmlTooltip>
                                </div>
                            </th>
                            <th className={styles.classHeader1} onClick={() => handleStringSorting("createdBy")}>
                                <div className={styles.headingContainer}>
                                    Created By
                                    <FaSort className={styles.iconsStyleSort} />
                                </div>
                            </th>
                            <th className={styles.typeHeader}>
                                <div className={styles.headingContainer}>
                                    View
                                </div>
                            </th>
                        </tr>
                    </thead>
                </table>
                <div className={styles.reportsTableBody} style={{ maxHeight: '44vh', overflowY: 'scroll', width: '100%' }}>
                    {patientDiagnosesLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (diagnosesListSorting.length > 0) ? diagnosesTableBody : (<div className={styles.circularProgress}>{"No diagnoses added."}</div>)}
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showEditDx}
                onClose={e => closeEditDxDialog(e)}
                hideBackdrop={true}
            >
                <div className={styles.paper}>
                    <div className={styles.mainWrapper}>
                        <div className={styles.addDxHeader}>Edit Diagnosis</div>
                        <div className={styles.addDxBody}>

                            <div className={styles.rowDiv}>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Code*</div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={selectedCode} onChange={(e) => setSelectedCode(e.currentTarget.value)} className={styles.inputFieldSelctedDisabled} onFocus={(e) => handleFocus(e)} disabled />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Description*</div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={selectedDesc} onChange={(e) => setSelectedDesc(e.currentTarget.value)} className={styles.inputFieldSelctedDisabled} onFocus={(e) => handleFocus(e)} disabled />
                                    </div>
                                </div>
                            </div>

                            <div className={styles.rowDiv1}>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Program*</div>
                                    <div>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={program}
                                            onChange={setProgram}
                                            options={optionsProgram}
                                            color="#4E253A"
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Rank</div>
                                    <div>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={rank}
                                            onChange={setRank}
                                            options={optionsRank}
                                            color="#4E253A"
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Classification</div>
                                    <div>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={classification}
                                            onChange={setClassification}
                                            options={optionsClassification}
                                            color="#4E253A"
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.checkBoxDiv}><Checkbox checked={therapy} disabled={!isEdit} onChange={event => handleTherapyCheckboxChange(event.target.checked)} sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#A040AB' } }} /></div>
                                    <div className={styles.checkBoxLabel}>Therapy</div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.checkBoxDiv}><Checkbox checked={confidential} disabled={!isEdit} onChange={event => handleConfidentialCheckboxChange(event.target.checked)} sx={{ '& .MuiSvgIcon-root': { fontSize: 24, color: '#A040AB' } }} /></div>
                                    <div className={styles.checkBoxLabel}>Confidential</div>
                                </div>
                            </div>

                            <div className={styles.rowDiv1}>
                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Onset*</div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={onset}
                                            onChange={setOnset}
                                            options={optionsOnset}
                                            color="#4E253A"
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>
                                        Date (YYYY-MM-DD)
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input
                                            value={isOnsetDateDisabled ? `----/--/--` : `${format(onsetDate, "yyyy/MM/dd")}`}
                                            readOnly
                                            className={(onset[0] && onset[0].value === 'Admission Date') ? styles.inputFieldSelctedDOBDisabled : (isOnsetDateDisabled || !isEdit) ? styles.inputFieldSelctedDOBDisabled : styles.inputFieldSelctedDOB}
                                            onClick={(event) => handleCalClick(event)}
                                            disabled={isOnsetDateDisabled || !isEdit}
                                        />
                                        {isCalOpen && (
                                            <div className={styles.datePickerWrapper}>
                                                <Calendar
                                                    onChange={item => setOnsetDate(item)}
                                                    editableDateInputs={false}
                                                    showSelectionPreview={true}
                                                    months={1}
                                                    date={onsetDate}
                                                    direction="horizontal"
                                                    maxDate={addDays(new Date(), 1)}
                                                    color={["#aa346f"]}
                                                />
                                                <div className={styles.applyButtonDiv}>
                                                    <div className={styles.cancelButton1} onClick={(event) => handleCalClickCancel(event)}> Cancel </div>
                                                    <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>Status*</div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={styles.dropdown}
                                            values={status}
                                            onChange={setStatus}
                                            options={optionsStatus}
                                            color="#4E253A"
                                            disabled={!isEdit}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv}>
                                    <div className={styles.fieldLabel}>
                                        Date (YYYY-MM-DD)
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input
                                            value={isResolvedDateDisabled ? `----/--/--` : `${format(resolvedDate, "yyyy/MM/dd")}`}
                                            readOnly
                                            className={(isResolvedDateDisabled || !isEdit) ? styles.inputFieldSelctedDOBDisabled : styles.inputFieldSelctedDOB}
                                            onClick={(event) => handleCalClick1(event)}
                                            disabled={isResolvedDateDisabled || !isEdit}
                                        />
                                        {isCalOpen1 && (
                                            <div className={styles.datePickerWrapper}>
                                                <Calendar
                                                    onChange={item => setResolvedDate(item)}
                                                    editableDateInputs={false}
                                                    showSelectionPreview={true}
                                                    months={1}
                                                    date={resolvedDate}
                                                    direction="horizontal"
                                                    maxDate={addDays(new Date(), 1)}
                                                    color={["#aa346f"]}
                                                />
                                                <div className={styles.applyButtonDiv}>
                                                    <div className={styles.cancelButton1} onClick={(event) => handleCalClickCancel1(event)}> Cancel </div>
                                                    <div className={styles.applyButton} onClick={(event) => handleCalClick1(event)}> Confirm </div>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.rowDiv2}>
                                <div className={styles.fieldLabel1}>
                                    Notes
                                </div>
                                <div className={styles.addNewNoteDiv}>
                                    <Input
                                        className={styles.notesEditor}
                                        type="text"
                                        placeholder="Start typing..."
                                        value={comment}
                                        multiline={true}
                                        onChange={(event) => setComment(event.target.value)}
                                        disableUnderline="true"
                                        disabled={!isEdit}
                                    />
                                </div>
                            </div>

                            <div className={styles.rowDiv3}>
                                <div className={styles.errMsgDiv}>
                                    {errMsg}
                                </div>
                                <div className={styles.buttonsDiv}>
                                    {isEdit ? (<>
                                        <div className={styles.cancelButton} onClick={e => handleCancelButton1(e)}>
                                            Cancel
                                        </div>
                                        <div className={styles.saveButton} onClick={e => handleSaveButton(e)}>
                                            Save
                                        </div>
                                    </>) : (<>
                                        <div className={styles.cancelButton} onClick={e => handleCancelButton(e)}>
                                            Close
                                        </div>
                                        <div className={styles.saveButton} onClick={e => handleEditButton(e)}>
                                            Edit
                                        </div>
                                    </>)}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        </div>
    )

}