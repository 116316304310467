import styles from "./ProfilePayerTab.module.css";
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react";
import Select from 'react-dropdown-select';
import Tooltip from "@material-ui/core/Tooltip";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { setPRPayerInfo, setRegistrationStatus, setShowPayerInfoLoader } from "../../../../Actions/UtilActions";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentPatientGUID, getPayerInfoLoader, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { getFacilityBPHDropdownListSelector, getPatientInfoSelector, getPayerFormEdit1, getPayerFormEdit2, getPayerFormEdit3 } from "../../../../Reducer/selectors/PatientSelector";
import { getFacilityBPH, getFacilityBPHDropdownList, getPatientInfo, setPayerFormEdit1, setPayerFormEdit2, setPayerFormEdit3, updatePayerInfo } from "../../../../Actions";
import { getSelectedProjectIdAndLocationId, getSelectedSubjectId } from "../../../../Reducer/selectors/DashboardSelector";
import CircularProgress from "@material-ui/core/CircularProgress";

const ProfilePayerTab = forwardRef((props, ref) => {

    const handleFocus = (event) => event.target.select();
    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const patientInfoObj = useSelector(getPatientInfoSelector);
    //console.log('patientInfoObj: ', patientInfoObj);

    const [payerType, setPayerType] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerType : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerType : '' : '' }]);
    const [payerTypeOther, setPayerTypeOther] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.ifOther : '' : '');
    const [priPayerName, setPriPayerName] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerName : '' : '');
    const [medicareBeneId, setMedicareBeneId] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.medicareId : '' : '');
    const [payerRel, setPayerRel] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerId : '' : '');
    const [medicaidBeneId, setMedicaidBeneId] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.medicaidId : '' : '');
    const [managedCareId, setManagedCareId] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.careId : '' : '');
    const [priPayerPh, setPriPayerPh] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.phone : '' : '');
    const [edu, setEdu] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.education ? patientInfoObj.subjectMore.education : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.education ? patientInfoObj.subjectMore.education : '' : '' }]);
    const [occupation, setOccupation] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.occupation ? patientInfoObj.subjectMore.occupation : '' : '');
    const [empStatus, setEmpStatus] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.employment ? patientInfoObj.subjectMore.employment : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.employment ? patientInfoObj.subjectMore.employment : '' : '' }]);
    const [religion, setReligion] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.religion ? patientInfoObj.subjectMore.religion : '' : '');
    const [marStatus, setMarStatus] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.marital ? patientInfoObj.subjectMore.marital : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.marital ? patientInfoObj.subjectMore.marital : '' : '' }]);
    const [ethnicity, setEthnicity] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ethnicity ? patientInfoObj.subjectMore.ethnicity : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ethnicity ? patientInfoObj.subjectMore.ethnicity : '' : '' }]);
    const [smokeStatus, setSmokeStatus] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.smoking ? patientInfoObj.subjectMore.smoking : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.smoking ? patientInfoObj.subjectMore.smoking : '' : '' }]);
    const [race, setRace] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.race ? patientInfoObj.subjectMore.race : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.race ? patientInfoObj.subjectMore.race : '' : '' }]);
    const [primLang, setPrimLang] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.language ? patientInfoObj.subjectMore.language : '' : '');
    const [primProg, setPrimProg] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program1 ? patientInfoObj.subjectMore.program1 : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program1 ? patientInfoObj.subjectMore.program1 : '' : '' }]);
    const [interpNeed, setInterpNeed] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ipNeeded ? patientInfoObj.subjectMore.ipNeeded : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ipNeeded ? patientInfoObj.subjectMore.ipNeeded : '' : '' }]);
    const [secProg, setSecProg] = useState([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program2 ? patientInfoObj.subjectMore.program2 : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program2 ? patientInfoObj.subjectMore.program2 : '' : '' }]);
    const [height, setHeight] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.height ? patientInfoObj.subjectMore.height : '' : '');
    const [weight, setWeight] = useState(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.weight ? patientInfoObj.subjectMore.weight : '' : '');
    const [billPhy, setBillPhy] = useState([{ value: patientInfoObj.billingPhysicianId ? patientInfoObj.billingPhysicianId : '', label: patientInfoObj.billingPhysician ? patientInfoObj.billingPhysician : '' }]);

    const [showReqWarn, setShowReqWarn] = useState(false);

    const [isBillPhyFill, setIsBillPhyFill] = useState(true);
    const [isPrimProgFill, setIsPrimProgFill] = useState(true);


    const selectedSubjectId = useSelector(getSelectedSubjectId);
    const currSubjectGUID = useSelector(getCurrentPatientGUID);

    const isPayerFormEditable1 = useSelector(getPayerFormEdit1);
    const isPayerFormEditable2 = useSelector(getPayerFormEdit2);
    const isPayerFormEditable3 = useSelector(getPayerFormEdit3);

    const payerInfoLoader = useSelector(getPayerInfoLoader);

    const optionsBillPhy = useSelector(getFacilityBPHDropdownListSelector);

    const sleep = ms => new Promise(r => setTimeout(r, ms));

    const setAllFields1 = () => {
        console.log('setAllFields1', patientInfoObj.subjectMore.insurance.medicaidId);
        setPayerType([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerType ? patientInfoObj.subjectMore.insurance.payerType : '' : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerType ? patientInfoObj.subjectMore.insurance.payerType : '' : '' : '' }]);
        setPayerTypeOther(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.ifOther ? patientInfoObj.subjectMore.insurance.ifOther : '' : '' : '');
        setPriPayerName(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerName ? patientInfoObj.subjectMore.insurance.payerName : '' : '' : '');
        setMedicareBeneId(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.medicareId ? patientInfoObj.subjectMore.insurance.medicareId : '' : '' : '');
        setPayerRel(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.payerId ? patientInfoObj.subjectMore.insurance.payerId : '' : '' : '');
        setMedicaidBeneId(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.medicaidId ? patientInfoObj.subjectMore.insurance.medicaidId ? patientInfoObj.subjectMore.insurance.medicaidId : '' : '' : '' : '');
        setManagedCareId(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.careId ? patientInfoObj.subjectMore.insurance.careId : '' : '' : '');
        setPriPayerPh(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.insurance ? patientInfoObj.subjectMore.insurance.phone ? patientInfoObj.subjectMore.insurance.phone : '' : '' : '');
    };

    const setAllFields2 = () => {
        setEdu([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.education ? patientInfoObj.subjectMore.education : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.education ? patientInfoObj.subjectMore.education : '' : '' }]);
        setOccupation(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.occupation ? patientInfoObj.subjectMore.occupation : '' : '');
        setEmpStatus([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.employment ? patientInfoObj.subjectMore.employment : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.employment ? patientInfoObj.subjectMore.employment : '' : '' }]);
        setReligion(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.religion ? patientInfoObj.subjectMore.religion : '' : '');
        setMarStatus([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.marital ? patientInfoObj.subjectMore.marital : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.marital ? patientInfoObj.subjectMore.marital : '' : '' }]);
        setEthnicity([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ethnicity ? patientInfoObj.subjectMore.ethnicity : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ethnicity ? patientInfoObj.subjectMore.ethnicity : '' : '' }]);
        setSmokeStatus([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.smoking ? patientInfoObj.subjectMore.smoking : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.smoking ? patientInfoObj.subjectMore.smoking : '' : '' }]);
        setRace([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.race ? patientInfoObj.subjectMore.race : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.race ? patientInfoObj.subjectMore.race : '' : '' }]);
    };

    const setAllFields3 = () => {
        setPrimLang(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.language ? patientInfoObj.subjectMore.language : '' : '');
        setPrimProg([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program1 ? patientInfoObj.subjectMore.program1 : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program1 ? patientInfoObj.subjectMore.program1 : '' : '' }]);
        setInterpNeed([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ipNeeded ? patientInfoObj.subjectMore.ipNeeded : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.ipNeeded ? patientInfoObj.subjectMore.ipNeeded : '' : '' }]);
        setSecProg([{ value: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program2 ? patientInfoObj.subjectMore.program2 : '' : '', label: patientInfoObj.subjectMore ? patientInfoObj.subjectMore.program2 ? patientInfoObj.subjectMore.program2 : '' : '' }]);
        setHeight(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.height ? patientInfoObj.subjectMore.height : '' : '');
        setWeight(patientInfoObj.subjectMore ? patientInfoObj.subjectMore.weight ? patientInfoObj.subjectMore.weight : '' : '');
        setBillPhy([{ value: patientInfoObj.billingPhysician ? patientInfoObj.billingPhysician : '', label: patientInfoObj.billingPhysician ? patientInfoObj.billingPhysician : '' }]);
    };

    const clearAllPayerFields = () => {
        setPayerType([]);
        setPayerTypeOther('');
        setPriPayerName('');
        setMedicareBeneId('');
        setPayerRel('');
        setMedicaidBeneId('');
        setManagedCareId('');
        setPriPayerPh('');
        setEdu([]);
        setOccupation('');
        setEmpStatus([]);
        setReligion('');
        setMarStatus([]);
        setEthnicity([]);
        setSmokeStatus([]);
        setRace([]);
        setPrimLang('');
        setPrimProg([]);
        setInterpNeed([]);
        setSecProg([]);
        setHeight('');
        setWeight('');
        resetReqFills();
    };

    const resetReqFills = () => {
        setIsBillPhyFill(true);
        setIsPrimProgFill(true);
    };

    useImperativeHandle(ref, () => {
        return {
            clearAllPayerFields: clearAllPayerFields,
        };
    });

    const optionsPayerType = [
        { value: 'Managed Care', label: 'Managed Care' },
        { value: 'Medicare A', label: 'Medicare A' },
        { value: 'Medicare B', label: 'Medicare B' },
        { value: 'Medicaid', label: 'Medicaid' },
        { value: 'Private', label: 'Private' },
        { value: 'Other', label: 'Other' },
    ];

    const optionsEdu = [
        { value: '8th Grade/less', label: '8th Grade/less' },
        { value: 'High School', label: 'High School' },
        { value: 'Bachelor Degree', label: 'Bachelor Degree' },
        { value: 'Graduate Degree', label: 'Graduate Degree' },
        { value: 'No Schooling', label: 'No Schooling' },
        { value: 'Technical or Trade School', label: 'Technical or Trade School' },
        { value: 'Some College', label: 'Some College' },
    ];

    const optionsMarStatus = [
        { value: 'Single', label: 'Single' },
        { value: 'Divorced', label: 'Divorced' },
        { value: 'Married', label: 'Married' },
        { value: 'Never Married', label: 'Never Married' },
        { value: 'Not Assessed', label: 'Not Assessed' },
        { value: 'Separated', label: 'Separated' },
        { value: 'Widowed', label: 'Widowed' },
    ];

    const optionsEthnicity = [
        { value: 'Hispanic', label: 'Hispanic' },
        { value: 'Non-Hispanic', label: 'Non-Hispanic' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsSmokeStatus = [
        { value: 'Frequent', label: 'Frequent' },
        { value: 'Occasional', label: 'Occasional' },
        { value: 'Non-Smoker', label: 'Non-Smoker' },
    ];

    const optionsRace = [
        { value: 'American Indian or Alaska Native', label: 'American Indian or Alaska Native' },
        { value: 'Asian', label: 'Asian' },
        { value: 'Black or African American', label: 'Black or African American' },
        { value: 'Hispanic or Latino', label: 'Hispanic or Latino' },
        { value: 'Native Hawaiian', label: 'Native Hawaiian' },
        { value: 'White', label: 'White' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsEmpStatus = [
        { value: 'Student', label: 'Student' },
        { value: 'Unemployed', label: 'Unemployed' },
        { value: 'Full-time', label: 'Full-time' },
        { value: 'Part-time', label: 'Part-time' },
        { value: 'Self-Employed', label: 'Self-Employed' },
        { value: 'Retired', label: 'Retired' },
        { value: 'Unable to Work', label: 'Unable to Work' },
    ];

    const optionsPrimProg = [
        { value: 'Remote Patient Monitoring (RPM)', label: 'Remote Patient Monitoring (RPM)' },
        { value: 'Chronic Care Management (CCM)', label: 'Chronic Care Management (CCM)' },
        { value: 'Transitional Care Management (TCM)', label: 'Transitional Care Management (TCM)' },
        { value: 'Behavioral Health Integration (BHI)', label: 'Behavioral Health Integration (BHI)' },
        { value: 'Others', label: 'Others' },
    ];

    const optionsInterpNeed = [
        { value: 'Yes', label: 'Yes' },
        { value: 'No', label: 'No' },
        { value: 'Unable to be determined', label: 'Unable to be determined' },
    ];

    const optionsSecProg = [
        { value: 'Remote Patient Monitoring (RPM)', label: 'Remote Patient Monitoring (RPM)' },
        { value: 'Chronic Care Management (CCM)', label: 'Chronic Care Management (CCM)' },
        { value: 'Transitional Care Management (TCM)', label: 'Transitional Care Management (TCM)' },
        { value: 'Behavioral Health Integration (BHI)', label: 'Behavioral Health Integration (BHI)' },
        { value: 'Others', label: 'Others' },
    ];

    const HtmlTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} />
    ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
            backgroundColor: '#f5f5f9',
            color: 'rgba(0, 0, 0, 0.87)',
            maxWidth: 220,
            fontSize: theme.typography.pxToRem(10),
            border: '1px solid #dadde9',
        },
    }));

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label && state.values[0].label.length > 20) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 20)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const markStepCompleted = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: true,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: curRegStatus.step5,
            review: curRegStatus.review,
        };
        dispatch(setRegistrationStatus(newRegStatus));
    };

    const setTabInfo = async () => {

        const payload = {
            subjectId: selectedSubjectId.selectedSubjectId,
            payerType: payerType[0] ? payerType[0].value : '',
            ifOther: payerTypeOther,
            payerName: priPayerName,
            medicareId: medicareBeneId,
            payerId: payerRel,
            medicaidId: medicaidBeneId,
            careId: managedCareId,
            phone: priPayerPh,
            education: edu[0] ? edu[0].value : '',
            occupation: occupation,
            employment: empStatus[0] ? empStatus[0].value : '',
            religion: religion,
            marital: marStatus[0] ? marStatus[0].value : '',
            ethnicity: ethnicity[0] ? ethnicity[0].value : '',
            smoking: smokeStatus[0] ? smokeStatus[0].value : '',
            race: race[0] ? race[0].value : '',
            language: primLang,
            program1: primProg[0] ? primProg[0].value : '',
            ipNeeded: interpNeed[0] ? interpNeed[0].value : '',
            program2: secProg[0] ? secProg[0].value : '',
            height: height,
            weight: weight,
            bphId: billPhy[0] ? billPhy[0].value : '',
        };

        console.log("payerInfoTab payload: ", payload);
        dispatch(setShowPayerInfoLoader(true));
        dispatch(updatePayerInfo(payload));
        dispatch(setPayerFormEdit1(false));
        dispatch(setPayerFormEdit2(false));
        dispatch(setPayerFormEdit3(false));
        await sleep(3000);
        dispatch(getPatientInfo(currSubjectGUID));
    };

    const checkIfPageConditionsMet = () => {
        if ((primProg[0] && primProg[0].value !== '') && (billPhy[0] && billPhy[0].value !== '')) {
            // console.log('conditions met!');
            resetReqFills();
            setShowReqWarn(false);
            return true;
        } else {
            // console.log('conditions not met!');
            resetReqFills();
            if (!primProg[0] || primProg[0].value === '') {
                setIsPrimProgFill(false);
            };
            if (!billPhy[0] || billPhy[0].value === '') {
                setIsBillPhyFill(false);
            };
            setShowReqWarn(true);
            return false;
        }
    };

    const handleSave = () => {
        if (checkIfPageConditionsMet()) {
            setTabInfo();
        }
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const editPayerForm1 = (event) => {
        event.preventDefault();
        dispatch(setPayerFormEdit1(true));
    };


    const editPayerForm2 = (event) => {
        event.preventDefault();
        dispatch(setPayerFormEdit2(true));
    };


    const editPayerForm3 = (event) => {
        event.preventDefault();
        dispatch(setPayerFormEdit3(true));
    };

    const cancelEdit1 = () => {
        setAllFields1();
        dispatch(setPayerFormEdit1(false));
    };

    const handleSavePayer1 = () => {
        handleSave();
    };

    const cancelEdit2 = () => {
        setAllFields2();
        dispatch(setPayerFormEdit2(false));
    };

    const handleSavePayer2 = () => {
        handleSave();
    };

    const cancelEdit3 = () => {
        setAllFields3();
        dispatch(setPayerFormEdit3(false));
    };

    const handleSavePayer3 = () => {
        handleSave();
    };

    return (
        <>
            {payerInfoLoader ? (<div className={styles.circularProgress}><CircularProgress thickness={5.0} /></div>) : (<>
                <div className={styles.containerDiv}>
                    <div className={styles.headerDiv}>
                        <div></div>
                        <div>Payer Information</div>
                        <div className={styles.editButtonDiv}>
                            <div className={!isPayerFormEditable1 ? styles.editButton : styles.editButtonClicked} onClick={event => editPayerForm1(event)}>Edit</div>
                        </div>
                    </div>
                    <div className={styles.dumDiv}>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Payer Type
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable1 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable1}
                                            values={payerType}
                                            onChange={setPayerType}
                                            options={optionsPayerType}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        If Others, Please Specify
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={payerTypeOther} onChange={(e) => setPayerTypeOther(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Primary Payer Name
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={priPayerName} onChange={(e) => setPriPayerName(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Medicare Beneficiary ID
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={medicareBeneId} onChange={(e) => setMedicareBeneId(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName2}>
                                        Primary Payer ID or Relationship
                                        <div className={styles.infoButton}>
                                            <HtmlTooltip
                                                title={
                                                    <React.Fragment>
                                                        <div className={styles.circlesListItem} > Indicated payer ID or the relationship with the Patient </div>
                                                    </React.Fragment>
                                                }
                                            >
                                                <InfoOutlinedIcon style={{ height: '14px', width: '14px', fill: "#A040AB", marginRight: "5px", marginLeft: "5px" }} />
                                            </HtmlTooltip>
                                        </div>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={payerRel} onChange={(e) => setPayerRel(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Medicaid Beneficiary ID
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={medicaidBeneId} onChange={(e) => setMedicaidBeneId(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Primary Payer Phone Number
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={priPayerPh} onChange={(e) => setPriPayerPh(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Managed Care ID
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={managedCareId} onChange={(e) => setManagedCareId(e.currentTarget.value)} disabled={!isPayerFormEditable1} className={isPayerFormEditable1 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isPayerFormEditable1 && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit1}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSavePayer1}>Save</div>
                        </div>)}
                    </div>
                </div>


                <div className={styles.containerDiv1}>
                    <div className={styles.headerDiv}>
                        <div></div>
                        <div>Sociodemographic Information</div>
                        <div className={styles.editButtonDiv}>
                            <div className={!isPayerFormEditable2 ? styles.editButton : styles.editButtonClicked} onClick={event => editPayerForm2(event)}>Edit</div>
                        </div>
                    </div>
                    <div className={styles.dumDiv}>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Education
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable2}
                                            values={edu}
                                            onChange={setEdu}
                                            options={optionsEdu}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Occupation
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={occupation} onChange={(e) => setOccupation(e.currentTarget.value)} disabled={!isPayerFormEditable2} className={isPayerFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Employment Status
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable2}
                                            values={empStatus}
                                            onChange={setEmpStatus}
                                            options={optionsEmpStatus}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Religion
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={religion} onChange={(e) => setReligion(e.currentTarget.value)} disabled={!isPayerFormEditable2} className={isPayerFormEditable2 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column3}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Marital Status
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable2}
                                            values={marStatus}
                                            onChange={setMarStatus}
                                            options={optionsMarStatus}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Ethnicity
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable2}
                                            values={ethnicity}
                                            onChange={setEthnicity}
                                            options={optionsEthnicity}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column4}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Smoking Status
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable2}
                                            values={smokeStatus}
                                            onChange={setSmokeStatus}
                                            options={optionsSmokeStatus}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Race
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable2 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable2}
                                            values={race}
                                            onChange={setRace}
                                            options={optionsRace}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {isPayerFormEditable2 && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit2}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSavePayer2}>Save</div>
                        </div>)}
                    </div>
                </div>


                <div className={styles.containerDiv2}>
                    <div className={styles.headerDiv}>
                        <div></div>
                        <div>Programs</div>
                        <div className={styles.editButtonDiv}>
                            <div className={!isPayerFormEditable3 ? styles.editButton : styles.editButtonClicked} onClick={event => editPayerForm3(event)}>Edit</div>
                        </div>
                    </div>
                    <div className={styles.dumDiv}>
                        <div className={styles.contentDiv}>
                            <div className={styles.column1}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Primary Language
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={primLang} onChange={(e) => setPrimLang(e.currentTarget.value)} disabled={!isPayerFormEditable3} className={isPayerFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Primary Program <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable3}
                                            values={primProg}
                                            onChange={setPrimProg}
                                            options={optionsPrimProg}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column2}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Interpreter Needed
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable3}
                                            values={interpNeed}
                                            onChange={setInterpNeed}
                                            options={optionsInterpNeed}
                                            color="#4E253A"
                                            menuIsOpen={true}
                                        />
                                    </div>
                                </div>

                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Secondary Program
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable3}
                                            values={secProg}
                                            onChange={setSecProg}
                                            options={optionsSecProg}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column31}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Height (in.)
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={height} onChange={(e) => setHeight(e.currentTarget.value)} disabled={!isPayerFormEditable3} className={isPayerFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Billing Physician <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <Select
                                            contentRenderer={customContentRenderer}
                                            className={isPayerFormEditable3 ? styles.dropdown2E : styles.dropdown2}
                                            disabled={!isPayerFormEditable3}
                                            values={billPhy}
                                            onChange={setBillPhy}
                                            options={optionsBillPhy}
                                            color="#4E253A"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className={styles.column41}>
                                <div className={styles.fieldDiv1}>
                                    <div className={styles.fieldName}>
                                        Weight (lbs.)
                                    </div>
                                    <div className={styles.fieldInput}>
                                        <input type="text" value={weight} onChange={(e) => setWeight(e.currentTarget.value)} disabled={!isPayerFormEditable3} className={isPayerFormEditable3 ? styles.inputFieldSelctedE : styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                    </div>
                                </div>

                            </div>
                        </div>
                        {showReqWarn && <div className={styles.consentWarningDiv}>
                            Please fill out the required fields marked with an *
                        </div>}

                        {isPayerFormEditable3 && (<div className={styles.pageButtonsDiv}>
                            <div className={styles.cancelButton} onClick={cancelEdit3}>Cancel</div>
                            <div className={styles.saveButton} onClick={handleSavePayer3}>Save</div>
                        </div>)}
                    </div>
                </div>
            </>)}
        </>)
});

export default ProfilePayerTab;