import { useDispatch, useSelector } from "react-redux";
import styles from "./ReviewTab.module.css";
import { getPRCareTeamInfo, getPRFacilityInfo, getPRFamilyInfo, getPRGeneralInfo, getPRLoader, getPRMessage, getPRPayerInfo, getPRSuccess, getRegistrationStatus } from "../../../../Reducer/selectors/UtilSelector";
import { setPRCareTeamInfo, setPRFacilityInfo, setPRFamilyInfo, setPRGeneralInfo, setPRPayerInfo, setRegistrationStatus, setRegistrationStep, setShowPRLoader } from "../../../../Actions/UtilActions";
import { addDays, format } from "date-fns";
import { useEffect, useRef, useState } from "react";
import { registerNewPatient } from "../../../../Actions";
import { getSelectedProjectIdAndLocationId, getUserProfile } from "../../../../Reducer/selectors/DashboardSelector";
import Select from 'react-dropdown-select';
import { Calendar } from "react-date-range";
import Backdrop from '@mui/material/Backdrop';
import Modal from "@material-ui/core/Modal";
import { RiCloseCircleFill } from "react-icons/ri";
import { IconButton } from "@material-ui/core";
import { CircularProgress } from "@material-ui/core";
import { useNavigate } from "react-router-dom";

export default function ReviewTab(props) {

    const handleFocus = (event) => event.target.select();

    const generalInfo = useSelector(getPRGeneralInfo);
    const payerInfo = useSelector(getPRPayerInfo);
    const familyInfo = useSelector(getPRFamilyInfo);
    const careTeamInfo = useSelector(getPRCareTeamInfo);
    const facilityInfo = useSelector(getPRFacilityInfo);

    const [consentDate, setConsentDate] = useState(new Date());
    const [consentProvider, setConsentProvider] = useState('');
    const [providerOfConsent, setProviderOfConsent] = useState([]);
    const [relToP, setRelToP] = useState('');
    const [isCalOpen, setIsCalOpen] = useState(false);

    const [isProviderOfConsentFill, setIsProviderOfConsentFill] = useState(true);

    const resetReqFills = () => {
        setIsProviderOfConsentFill(true);
    };

    const refOne = useRef(null);

    const [consentSel, setConsentSel] = useState("No");

    const dispatch = useDispatch();

    const curRegStatus = useSelector(getRegistrationStatus);

    const projAndLoc = useSelector(getSelectedProjectIdAndLocationId);
    const projectId = projAndLoc.selectedProjectId;
    const locationId = projAndLoc.selectedLocationId;

    const profile = useSelector(getUserProfile);

    const prLoader = useSelector(getPRLoader);
    const prSuccess = useSelector(getPRSuccess);
    console.log('prSuccess: ', prSuccess);
    const prMessage = useSelector(getPRMessage);

    const [showConsentWarn, setShowConsentWarn] = useState(false);

    // const [userEmail] = useState(profile.email);
    const [firstName] = useState(profile.firstName);
    const [lastName] = useState(profile.lastName);

    let username = firstName + " " + lastName;

    const handleCalClick = (event) => {
        if (isCalOpen) {
            setIsCalOpen(false);
        } else {
            setIsCalOpen(true);
        }
    };

    useEffect(() => {
        document.addEventListener("keydown", hideOnEscape, true);
        document.addEventListener("click", hideOnClickOutside, true);
    }, []);

    // hide dropdown on ESC press
    const hideOnEscape = (e) => {
        if (e.key === "Escape") {
            handleCalClick(e);
        }
    };

    // Hide dropdown on outside click
    const hideOnClickOutside = (e) => {
        if (refOne.current && !refOne.current.contains(e.target)) {
            handleCalClick(e);
        }
    };

    const optionsProviderOfConsent = [
        { value: 'Patient', label: 'Patient' },
        { value: 'Substitute Decision Maker', label: 'Substitute Decision Maker' },
    ];

    const customContentRenderer = ({ props, state }) => {
        if (state.values && state.values.length > 0) {
            if (state.values[0].label.length > 25) {
                return (
                    <div>
                        {state.values[0].label.slice(0, 25)}...
                    </div>
                );
            } else {
                return (
                    <div>{state.values[0].label}</div>
                );
            }
        }
    };

    const handleSubmitFinalPayload = () => {

        let finalPayload = {
            subject: {
                guid: null,
                ehr: generalInfo.ehrId === '' ? null : generalInfo.ehrId,
                registeredBy: username === '' ? null : username,
                status: null,
                projectLocation: {
                    project: {
                        id: projectId === '' ? null : projectId,
                    },
                    location: {
                        id: locationId === '' ? null : locationId,
                    },
                },
                contactInfo: {
                    firstName: generalInfo.firstName === '' ? null : generalInfo.firstName,
                    lastName: generalInfo.lastName === '' ? null : generalInfo.lastName,
                    prefix: generalInfo.prefix === '' ? null : generalInfo.prefix,
                    suffix: generalInfo.suffix === '' ? null : generalInfo.suffix,
                    middle: generalInfo.middleName === '' ? null : generalInfo.middleName,
                    prefName: generalInfo.prevName === '' ? null : generalInfo.prevName,
                    dob: generalInfo.dob === '' ? null : generalInfo.dob,
                    birthPlace: null,
                    gender: generalInfo.bioSex === '' ? null : generalInfo.bioSex,
                    identity: generalInfo.genderIdentity === '' ? null : generalInfo.genderIdentity,
                    sexOr: generalInfo.sexOrientation === '' ? null : generalInfo.sexOrientation,
                    phone: generalInfo.phNum === '' ? null : generalInfo.phNum,
                    prefCommMode: null,
                    npi: null,
                    ssn: generalInfo.ssn === '' ? null : generalInfo.ssn,
                    email: generalInfo.email === '' ? null : generalInfo.email,
                },
                address: {
                    addrLine1: generalInfo.address === '' ? null : generalInfo.address,
                    addrLine2: generalInfo.address2 === '' ? null : generalInfo.address2,
                    city: generalInfo.city === '' ? null : generalInfo.city,
                    state: generalInfo.state === '' ? null : generalInfo.state,
                    zip: generalInfo.zip === '' ? null : generalInfo.zip,
                    country: generalInfo.country === '' ? null : generalInfo.country,
                },
                subjectMore: {
                    insurance: {
                        payerType: payerInfo.payerType === '' ? null : payerInfo.payerType,
                        ifOther: payerInfo.payerTypeOther === '' ? null : payerInfo.payerTypeOther,
                        payerName: payerInfo.priPayerName === '' ? null : payerInfo.priPayerName,
                        payerId: payerInfo.payerRel === '' ? null : payerInfo.payerRel,
                        medicareId: payerInfo.medicareBeneId === '' ? null : payerInfo.medicareBeneId,
                        medicaidId: payerInfo.medicaidBeneId === '' ? null : payerInfo.medicaidBeneId,
                        careId: payerInfo.managedCareId === '' ? null : payerInfo.managedCareId,
                        phone: payerInfo.priPayerPh === '' ? null : payerInfo.priPayerPh,
                    },
                    familyConsent: familyInfo.consentSel === 'Yes',
                    education: payerInfo.edu === '' ? null : payerInfo.edu,
                    occupation: payerInfo.occupation === '' ? null : payerInfo.occupation,
                    race: payerInfo.race === '' ? null : payerInfo.race,
                    ethnicity: payerInfo.ethnicity === '' ? null : payerInfo.ethnicity,
                    employment: payerInfo.empStatus === '' ? null : payerInfo.empStatus,
                    religion: payerInfo.religion === '' ? null : payerInfo.religion,
                    facility: {
                        code: facilityInfo.actCode === '' ? null : facilityInfo.actCode,
                        entryDate: facilityInfo.admDate === '' ? null : facilityInfo.admDate,
                        floor: facilityInfo.floor === '' ? null : facilityInfo.floor,
                        unit: facilityInfo.unit === '' ? null : facilityInfo.unit,
                        room: facilityInfo.roomNum === '' ? null : facilityInfo.roomNum,
                        bed: facilityInfo.bedNum === '' ? null : facilityInfo.bedNum,
                        barcode: facilityInfo.barcode === '' ? null : facilityInfo.barcode,
                    },
                    marital: payerInfo.marStatus === '' ? null : payerInfo.marStatus,
                    smoking: payerInfo.smokeStatus === '' ? null : payerInfo.smokeStatus,
                    language: payerInfo.primLang === '' ? null : payerInfo.primLang,
                    ipNeeded: payerInfo.interpNeed === '' ? null : payerInfo.interpNeed,
                    height: payerInfo.height === '' ? null : payerInfo.height,
                    weight: payerInfo.weight === '' ? null : payerInfo.weight,
                    program1: payerInfo.primProg === '' ? null : payerInfo.primProg,
                    program2: payerInfo.secProg === '' ? null : payerInfo.secProg,
                    citizenship: null,//BUG
                },
                consent: {
                    date: format(consentDate, "yyyy-MM-dd"),
                    provider: providerOfConsent[0] ? providerOfConsent[0].value : null,
                    name: consentProvider === '' ? null : consentProvider,
                    relationship: relToP === '' ? null : relToP,
                },
            },
            familyContactList: getFamilyContactList(),
            caregiverList: getCareTeamList(),
            billPhyId: facilityInfo.billPhyId === '' ? null : payerInfo.billPhyId,
            //diagnosesList: getDiagnosisList(),
        };
        console.log("THE FINAL PAYLOAD IS: ", finalPayload);

        if (consentSel === "Yes" && (providerOfConsent[0] && providerOfConsent[0].value !== '')) {
            resetReqFills();
            setShowConsentWarn(false);
            setShowRegisterNewPatientPopUp(true);
            dispatch(setShowPRLoader(true));
            dispatch(registerNewPatient(finalPayload));
        } else {
            resetReqFills();
            setShowConsentWarn(true);
            console.log("Please approve the consent.");
            if (!providerOfConsent[0] || providerOfConsent[0].value === '') {
                setIsProviderOfConsentFill(false);
            }
        }
    };

    const getDiagnosisList = () => {
        if (payerInfo.primCond !== '' && payerInfo.secCond !== '' && payerInfo.terCond !== '' && payerInfo.otherCom !== '') {
            return ([
                {
                    type: 'Primary',
                    desc: payerInfo.primCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
                {
                    type: 'Secondary',
                    desc: payerInfo.secCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
                {
                    type: 'Tertiary',
                    desc: payerInfo.terCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
                {
                    type: 'Other Comorbidities',
                    desc: payerInfo.otherCom,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
            ]);
        } else if (payerInfo.primCond !== '' && payerInfo.secCond !== '' && payerInfo.terCond !== '') {
            return ([
                {
                    type: 'Primary',
                    desc: payerInfo.primCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
                {
                    type: 'Secondary',
                    desc: payerInfo.secCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
                {
                    type: 'Tertiary',
                    desc: payerInfo.terCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
            ]);
        } else if (payerInfo.primCond !== '' && payerInfo.secCond !== '') {
            return ([
                {
                    type: 'Primary',
                    desc: payerInfo.primCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
                {
                    type: 'Secondary',
                    desc: payerInfo.secCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
            ]);
        } else if (payerInfo.primCond !== '') {
            return ([
                {
                    type: 'Primary',
                    desc: payerInfo.primCond,
                    onsetDate: '',
                    code: '',
                    rankDesc: '',
                },
            ]);
        } else {
            return [];
        }
    };

    const getFamilyContactList = () => {
        if (familyInfo.firstNameC1 !== '' && familyInfo.firstNameC2 !== '' && familyInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC2 === '' ? null : familyInfo.firstNameC2,
                        lastName: familyInfo.lastNameC2 === '' ? null : familyInfo.lastNameC2,
                        prefix: familyInfo.prefixC2 === '' ? null : familyInfo.prefixC2,
                        suffix: familyInfo.suffixC2 === '' ? null : familyInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC2 === '' ? null : familyInfo.bioSexC2,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC2 === '' ? null : familyInfo.phNumC2,
                        prefCommMode: familyInfo.comModeC2 === '' ? null : familyInfo.comModeC2,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC2 === '' ? null : familyInfo.emailC2,
                    },
                    relationship: familyInfo.relC2 === '' ? null : familyInfo.relC2,
                    contactType: familyInfo.conTypeC2 === '' ? null : familyInfo.conTypeC2,
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC3 === '' ? null : familyInfo.firstNameC3,
                        lastName: familyInfo.lastNameC3 === '' ? null : familyInfo.lastNameC3,
                        prefix: familyInfo.prefixC3 === '' ? null : familyInfo.prefixC3,
                        suffix: familyInfo.suffixC3 === '' ? null : familyInfo.suffixC3,
                        middle: '',
                        prefName: '',
                        dob: '',
                        birthPlace: '',
                        gender: familyInfo.bioSexC3 === '' ? null : familyInfo.bioSexC3,
                        identity: '',
                        sexOr: '',
                        phone: familyInfo.phNumC3 === '' ? null : familyInfo.phNumC3,
                        prefCommMode: familyInfo.comModeC3 === '' ? null : familyInfo.comModeC3,
                        npi: '',
                        ssn: '',
                        email: familyInfo.emailC3 === '' ? null : familyInfo.emailC3,
                    },
                    relationship: familyInfo.relC3 === '' ? null : familyInfo.relC3,
                    contactType: familyInfo.conTypeC3 === '' ? null : familyInfo.conTypeC3,
                },
            ]);
        } else if (familyInfo.firstNameC1 !== '' && familyInfo.firstNameC2 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC2 === '' ? null : familyInfo.firstNameC2,
                        lastName: familyInfo.lastNameC2 === '' ? null : familyInfo.lastNameC2,
                        prefix: familyInfo.prefixC2 === '' ? null : familyInfo.prefixC2,
                        suffix: familyInfo.suffixC2 === '' ? null : familyInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC2 === '' ? null : familyInfo.bioSexC2,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC2 === '' ? null : familyInfo.phNumC2,
                        prefCommMode: familyInfo.comModeC2 === '' ? null : familyInfo.comModeC2,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC2 === '' ? null : familyInfo.emailC2,
                    },
                    relationship: familyInfo.relC2 === '' ? null : familyInfo.relC2,
                    contactType: familyInfo.conTypeC2 === '' ? null : familyInfo.conTypeC2,
                },
            ]);
        } else if (familyInfo.firstNameC1 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                },
            ]);
        } else if (familyInfo.firstNameC1 !== '' && familyInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC1 === '' ? null : familyInfo.firstNameC1,
                        lastName: familyInfo.lastNameC1 === '' ? null : familyInfo.lastNameC1,
                        prefix: familyInfo.prefixC1 === '' ? null : familyInfo.prefixC1,
                        suffix: familyInfo.suffixC1 === '' ? null : familyInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC1 === '' ? null : familyInfo.bioSexC1,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC1 === '' ? null : familyInfo.phNumC1,
                        prefCommMode: familyInfo.comModeC1 === '' ? null : familyInfo.comModeC1,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC1 === '' ? null : familyInfo.emailC1,
                    },
                    relationship: familyInfo.relC1 === '' ? null : familyInfo.relC1,
                    contactType: familyInfo.conTypeC1 === '' ? null : familyInfo.conTypeC1,
                },
                {
                    contactInfo: {
                        firstName: familyInfo.firstNameC3 === '' ? null : familyInfo.firstNameC3,
                        lastName: familyInfo.lastNameC3 === '' ? null : familyInfo.lastNameC3,
                        prefix: familyInfo.prefixC3 === '' ? null : familyInfo.prefixC3,
                        suffix: familyInfo.suffixC3 === '' ? null : familyInfo.suffixC3,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: familyInfo.bioSexC3 === '' ? null : familyInfo.bioSexC3,
                        identity: null,
                        sexOr: null,
                        phone: familyInfo.phNumC3 === '' ? null : familyInfo.phNumC3,
                        prefCommMode: familyInfo.comModeC3 === '' ? null : familyInfo.comModeC3,
                        npi: null,
                        ssn: null,
                        email: familyInfo.emailC3 === '' ? null : familyInfo.emailC3,
                    },
                    relationship: familyInfo.relC3 === '' ? null : familyInfo.relC3,
                    contactType: familyInfo.conTypeC3 === '' ? null : familyInfo.conTypeC3,
                },
            ]);
        } else {
            return ([]);
        }
    };

    const getCareTeamList = () => {
        if (careTeamInfo.firstNameC1 !== '' && careTeamInfo.firstNameC2 !== '' && careTeamInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC2 === '' ? null : careTeamInfo.firstNameC2,
                        lastName: careTeamInfo.lastNameC2 === '' ? null : careTeamInfo.lastNameC2,
                        prefix: careTeamInfo.prefixC2 === '' ? null : careTeamInfo.prefixC2,
                        suffix: careTeamInfo.suffixC2 === '' ? null : careTeamInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC2 === '' ? null : careTeamInfo.phNumC2,
                        prefCommMode: careTeamInfo.comModeC2 === '' ? null : careTeamInfo.comModeC2,
                        npi: careTeamInfo.npiC2 === '' ? null : careTeamInfo.npiC2,
                        ssn: null,
                        email: careTeamInfo.emailC2 === '' ? null : careTeamInfo.emailC2,
                    },
                    profession: careTeamInfo.profC2 === '' ? null : careTeamInfo.profC2,
                    speciality: careTeamInfo.specC2 === '' ? null : careTeamInfo.specC2,
                    type: 'Secondary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC3 === '' ? null : careTeamInfo.firstNameC3,
                        lastName: careTeamInfo.lastNameC3 === '' ? null : careTeamInfo.lastNameC3,
                        prefix: careTeamInfo.prefixC3 === '' ? null : careTeamInfo.prefixC3,
                        suffix: careTeamInfo.suffixC3 === '' ? null : careTeamInfo.suffixC3,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC3 === '' ? null : careTeamInfo.phNumC3,
                        prefCommMode: careTeamInfo.comModeC3 === '' ? null : careTeamInfo.comModeC3,
                        npi: careTeamInfo.npiC3 === '' ? null : careTeamInfo.npiC3,
                        ssn: null,
                        email: careTeamInfo.emailC3 === '' ? null : careTeamInfo.emailC3,
                    },
                    profession: careTeamInfo.profC3 === '' ? null : careTeamInfo.profC3,
                    speciality: careTeamInfo.specC3 === '' ? null : careTeamInfo.specC3,
                    type: 'Tertiary',
                },
            ]);
        } else if (careTeamInfo.firstNameC1 !== '' && careTeamInfo.firstNameC2 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC2 === '' ? null : careTeamInfo.firstNameC2,
                        lastName: careTeamInfo.lastNameC2 === '' ? null : careTeamInfo.lastNameC2,
                        prefix: careTeamInfo.prefixC2 === '' ? null : careTeamInfo.prefixC2,
                        suffix: careTeamInfo.suffixC2 === '' ? null : careTeamInfo.suffixC2,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC2 === '' ? null : careTeamInfo.phNumC2,
                        prefCommMode: careTeamInfo.comModeC2 === '' ? null : careTeamInfo.comModeC2,
                        npi: careTeamInfo.npiC2 === '' ? null : careTeamInfo.npiC2,
                        ssn: null,
                        email: careTeamInfo.emailC2 === '' ? null : careTeamInfo.emailC2,
                    },
                    profession: careTeamInfo.profC2 === '' ? null : careTeamInfo.profC2,
                    speciality: careTeamInfo.specC2 === '' ? null : careTeamInfo.specC2,
                    type: 'Secondary',
                },
            ]);
        } else if (careTeamInfo.firstNameC1 !== '' && careTeamInfo.firstNameC3 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC3 === '' ? null : careTeamInfo.firstNameC3,
                        lastName: careTeamInfo.lastNameC3 === '' ? null : careTeamInfo.lastNameC3,
                        prefix: careTeamInfo.prefixC3 === '' ? null : careTeamInfo.prefixC3,
                        suffix: careTeamInfo.suffixC3 === '' ? null : careTeamInfo.suffixC3,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC3 === '' ? null : careTeamInfo.phNumC3,
                        prefCommMode: careTeamInfo.comModeC3 === '' ? null : careTeamInfo.comModeC3,
                        npi: careTeamInfo.npiC3 === '' ? null : careTeamInfo.npiC3,
                        ssn: null,
                        email: careTeamInfo.emailC3 === '' ? null : careTeamInfo.emailC3,
                    },
                    profession: careTeamInfo.profC3 === '' ? null : careTeamInfo.profC3,
                    speciality: careTeamInfo.specC3 === '' ? null : careTeamInfo.specC3,
                    type: 'Tertiary',
                },
            ]);
        } else if (careTeamInfo.firstNameC1 !== '') {
            return ([
                {
                    contactInfo: {
                        firstName: careTeamInfo.firstNameC1 === '' ? null : careTeamInfo.firstNameC1,
                        lastName: careTeamInfo.lastNameC1 === '' ? null : careTeamInfo.lastNameC1,
                        prefix: careTeamInfo.prefixC1 === '' ? null : careTeamInfo.prefixC1,
                        suffix: careTeamInfo.suffixC1 === '' ? null : careTeamInfo.suffixC1,
                        middle: null,
                        prefName: null,
                        dob: null,
                        birthPlace: null,
                        gender: null,
                        identity: null,
                        sexOr: null,
                        phone: careTeamInfo.phNumC1 === '' ? null : careTeamInfo.phNumC1,
                        prefCommMode: careTeamInfo.comModeC1 === '' ? null : careTeamInfo.comModeC1,
                        npi: careTeamInfo.npiC1 === '' ? null : careTeamInfo.npiC1,
                        ssn: null,
                        email: careTeamInfo.emailC1 === '' ? null : careTeamInfo.emailC1,
                    },
                    profession: careTeamInfo.profC1 === '' ? null : careTeamInfo.profC1,
                    speciality: careTeamInfo.specC1 === '' ? null : careTeamInfo.specC1,
                    type: 'Primary',
                },
            ]);
        } else {
            return ([]);
        }
    };

    const projLoc = useSelector(getSelectedProjectIdAndLocationId);
    const selectedProjectId = projLoc.selectedProjectId;
    const selectedLocationId = projLoc.selectedLocationId;

    const handleBackCLick = () => {
        const newRegStatus = {
            step1: curRegStatus.step1,
            step2: curRegStatus.step2,
            step3: curRegStatus.step3,
            step4: curRegStatus.step4,
            step5: false,
            review: false,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        props.tabChange("5");

        // navigate('/subjects/' + selectedProjectId + '/' + selectedLocationId);
    };

    const onValueChange = (event) => {
        // Updating the state with the selected radio button's value
        setConsentSel(event.target.value);
    };

    const [showRegisterNewPatientPopUp, setShowRegisterNewPatientPopUp] = useState(false);

    const navigate = useNavigate();

    const registerAnotherPatient = () => {
        setShowRegisterNewPatientPopUp(false);
        props.clearGeneralFields();
        clearAllRegistrationInfo();
        navigate('/register')
    };

    const redirectToPatientList = () => {
        setShowRegisterNewPatientPopUp(false);
        navigate('/subjects/' + projectId + '/' + locationId);
    }

    const handleTabRedirect = (event, key) => {
        event.preventDefault();
        props.tabChange(key);
    };

    const clearAllRegistrationInfo = () => {
        const newRegStatus = {
            step1: false,
            step2: false,
            step3: false,
            step4: false,
            step5: false,
            review: false,
        };
        dispatch(setRegistrationStatus(newRegStatus));
        dispatch(setRegistrationStep("1"));
        dispatch(setPRGeneralInfo({
            ehrId: '',
            prefix: '',
            firstName: '',
            middleName: '',
            suffix: '',
            lastName: '',
            prevName: '',
            dob: new Date(),
            ssn: '',
            bioSex: '',
            genderIdentity: '',
            sexOrientation: '',
            phNum: '',
            email: '',
            address: '',
            address2: '',
            city: '',
            state: '',
            zip: '',
            country: '',
        }));
        dispatch(setPRPayerInfo({
            payerType: '',
            payerTypeOther: '',
            priPayerName: '',
            medicareBeneId: '',
            payerRel: '',
            medicaidBeneId: '',
            managedCareId: '',
            priPayerPh: '',
            edu: '',
            occupation: '',
            empStatus: '',
            religion: '',
            marStatus: '',
            ethnicity: '',
            smokeStatus: '',
            race: '',
            primLang: '',
            primCond: '',
            primProg: '',
            interpNeed: '',
            secCond: '',
            secProg: '',
            height: '',
            terCond: '',
            weight: '',
            otherCom: '',
            billPhy: '',
            billPhyId: '',
        }));
        dispatch(setPRFamilyInfo({
            prefixC1: '',
            firstNameC1: '',
            suffixC1: '',
            lastNameC1: '',
            relC1: '',
            bioSexC1: '',
            emailC1: '',
            phNumC1: '',
            conTypeC1: [],
            comModeC1: [],

            prefixC2: '',
            firstNameC2: '',
            suffixC2: '',
            lastNameC2: '',
            relC2: '',
            bioSexC2: '',
            emailC2: '',
            phNumC2: '',
            conTypeC2: [],
            comModeC2: [],

            prefixC3: '',
            firstNameC3: '',
            suffixC3: '',
            lastNameC3: '',
            relC3: '',
            bioSexC3: '',
            emailC3: '',
            phNumC3: '',
            conTypeC3: [],
            comModeC3: [],

            consentSel: '',
        }));
        dispatch(setPRCareTeamInfo({
            prefixC1: '',
            firstNameC1: '',
            suffixC1: '',
            lastNameC1: '',
            specC1: '',
            profC1: '',
            npiC1: '',
            emailC1: '',
            phNumC1: '',
            comModeC1: [],

            prefixC2: '',
            firstNameC2: '',
            suffixC2: '',
            lastNameC2: '',
            specC2: '',
            profC2: '',
            npiC2: '',
            emailC2: '',
            phNumC2: '',
            comModeC2: [],

            prefixC3: '',
            firstNameC3: '',
            suffixC3: '',
            lastNameC3: '',
            specC3: '',
            profC3: '',
            npiC3: '',
            emailC3: '',
            phNumC3: '',
            comModeC3: [],
        }));
        dispatch(setPRFacilityInfo({
            facilityName: '',
            facilityNum: '',
            facilityType: '',
            bedNum: '',
            actCode: '',
            unit: '',
            floor: '',
            roomNum: '',
            admDate: new Date(),
            barcode: '',
        }));
    };

    document.addEventListener("wheel", function (event) {
        if (document.activeElement.type === "number") {
            document.activeElement.blur();
        }
    });

    const getDisplayName = (inStr) => {
        let len = inStr.length;
        if (len > 20) {
            return inStr.toString().substring(0, 17) + '...';
        }
        return inStr;
    };

    return (
        <div className={styles.rootDiv}>
            <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer - 1 }}
                open={showRegisterNewPatientPopUp}
            ></Backdrop>
            <div className={styles.containerDiv}>
                <div className={styles.headerDiv}>
                    Review
                </div>
                {generalInfo.firstName !== '' && (<div className={styles.dumDiv}>

                    <div className={styles.overviewDiv}>
                        <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "1")}>
                            General
                        </div>
                        <div className={styles.tabContentDiv}>
                            <div className={styles.contentColumn1}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        EHR or MRN ID:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.ehrId)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Prefix:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.prefix)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        First Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.firstName)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Middle Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.middleName)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Last Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.lastName)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Suffix:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.suffix)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Preferred Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.prevName)}
                                    </div>
                                </div>
                            </div>

                            <div className={styles.contentColumn2}>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        SSN:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.ssn)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Phone Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.phNum)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Email:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.email)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        DOB:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.dob.toString())}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.bioSex)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Gender Identity:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.genderIdentity)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Sexual Orientation:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.sexOrientation)}
                                    </div>
                                </div>

                            </div>
                            <div className={styles.contentColumn3}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Address:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.address)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Address 2:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.address2)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        City:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.city)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        State:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.state)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Zipcode:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.zip)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Country:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(generalInfo.country)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={styles.overviewDiv}>
                        <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "2")}>
                            Payer & Programs
                        </div>
                        <div className={styles.tabContentDiv}>
                            <div className={styles.contentColumn1}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Payer Type:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.payerType)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        If Others, Please Specify:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.payerTypeOther)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Primary Payer Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.priPayerName)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Primary Payer ID or Relationship:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.payerRel)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Primary Payer Phone Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.priPayerPh)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Medicare Beneficiary ID:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.medicareBeneId)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Medicaid Beneficiary ID:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.medicaidBeneId)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Managed Care ID:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.managedCareId)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn2}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Education:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.edu)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Employment Status:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.empStatus)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Occupation:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.occupation)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Religion:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.religion)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Marital Status:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.marStatus)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Ethnicity:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.ethnicity)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Smoking Status:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.smokeStatus)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Race:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.race)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Primary Language:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.primLang)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Interpreter Needed:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.interpNeed)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn3}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Primary Program:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.primProg)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Secondary Program:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.secProg)}
                                    </div>
                                </div>
                                {/* <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Primary Condition:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.primCond)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Secondary Condition:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.secCond)}
                                    </div>
                                </div>

                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Tertiary Condition:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.terCond)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Other Comorbidities:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.otherCom)}
                                    </div>
                                </div> */}
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Height:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.height)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Weight:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.weight)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Billing Physician:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(payerInfo.billPhy)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={styles.overviewDiv}>
                        <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "3")}>
                            Family Contact 1
                        </div>
                        <div className={styles.tabContentDiv}>
                            <div className={styles.contentColumn1}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Prefix:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.prefixC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        First Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.firstNameC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Last Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.lastNameC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Suffix:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.suffixC1)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn2}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Sex at Birth:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.bioSexC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Relationship to Patient:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.relC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Contact Type:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.conTypeC1)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn3}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Phone Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.phNumC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Email Address:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.emailC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of Communication:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(familyInfo.comModeC1)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {familyInfo.firstNameC2 !== '' ? (<>
                        <div className={styles.overviewDiv}>
                            <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "3")}>
                                Family Contact 2
                            </div>
                            <div className={styles.tabContentDiv}>
                                <div className={styles.contentColumn1}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Prefix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.prefixC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            First Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.firstNameC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Last Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.lastNameC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Suffix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.suffixC2)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn2}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Sex at Birth:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.bioSexC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Relationship to Patient:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.relC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Contact Type:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.conTypeC2)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn3}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Phone Number:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.phNumC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Email Address:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.emailC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of Communication:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.comModeC2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) : (<>
                    </>)}

                    {familyInfo.firstNameC3 !== '' ? (<>
                        <div className={styles.overviewDiv}>
                            <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "3")}>
                                Family Contact 3
                            </div>
                            <div className={styles.tabContentDiv}>
                                <div className={styles.contentColumn1}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Prefix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.prefixC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            First Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.firstNameC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Last Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.lastNameC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Suffix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.suffixC3)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn2}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Sex at Birth:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.bioSexC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Relationship to Patient:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.relC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Contact Type:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.conTypeC3)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn3}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Phone Number:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.phNumC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Email Address:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.emailC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of Communication:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(familyInfo.comModeC3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) : (<>
                    </>)}


                    <div className={styles.overviewDiv}>
                        <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "4")}>
                            Primary Care Team Info
                        </div>
                        <div className={styles.tabContentDiv}>
                            <div className={styles.contentColumn1}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Prefix:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.prefixC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        First Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.firstNameC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Last Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.lastNameC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Suffix:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.suffixC1)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn2}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Profession:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.profC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Speciality:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.specC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        NPI#:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.npiC1)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn3}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Phone Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.phNumC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Email Address:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.emailC1)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Preferred mode of Communication:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(careTeamInfo.comModeC1)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    {careTeamInfo.firstNameC2 !== '' ? (<>
                        <div className={styles.overviewDiv}>
                            <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "4")}>
                                Secondary Care Team Info
                            </div>
                            <div className={styles.tabContentDiv}>
                                <div className={styles.contentColumn1}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Prefix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.prefixC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            First Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.firstNameC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Last Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.lastNameC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Suffix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.suffixC2)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn2}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Profession:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.profC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Speciality:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.specC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            NPI#:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.npiC2)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn3}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Phone Number:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.phNumC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Email Address:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.emailC2)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of Communication:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.comModeC2)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) : (<></>)}


                    {careTeamInfo.firstNameC3 !== '' ? (<>
                        <div className={styles.overviewDiv}>
                            <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "4")}>
                                Tertiary Care Team Info
                            </div>
                            <div className={styles.tabContentDiv}>
                                <div className={styles.contentColumn1}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Prefix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.prefixC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            First Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.firstNameC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Last Name:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.lastNameC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Suffix:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.suffixC3)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn2}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Profession:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.profC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Speciality:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.specC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            NPI#:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.npiC3)}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.contentColumn3}>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Phone Number:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.phNumC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Email Address:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.emailC3)}
                                        </div>
                                    </div>
                                    <div className={styles.contentField}>
                                        <div className={styles.fieldName}>
                                            Preferred mode of Communication:
                                        </div>
                                        <div className={styles.fieldValue}>
                                            {getDisplayName(careTeamInfo.comModeC3)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>) : (<></>)}


                    <div className={styles.overviewDiv}>
                        <div className={styles.tabNameDiv} onClick={(event) => handleTabRedirect(event, "5")}>
                            Facility & Admission Info
                        </div>
                        <div className={styles.tabContentDiv}>
                            <div className={styles.contentColumn1}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Facility Name:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.facilityName)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Facility Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.facilityNum)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Facility Type:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.facilityType)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn2}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Action Code:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.actCode)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Admission Date:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.admDate.toString())}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Barcode:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.barcode)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Floor:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.floor)}
                                    </div>
                                </div>
                            </div>
                            <div className={styles.contentColumn3}>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Room Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.roomNum)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Unit/Wing/Zone:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.unit)}
                                    </div>
                                </div>
                                <div className={styles.contentField}>
                                    <div className={styles.fieldName}>
                                        Bed Number:
                                    </div>
                                    <div className={styles.fieldValue}>
                                        {getDisplayName(facilityInfo.bedNum)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={styles.headerDiv2}>
                        Consent
                    </div>
                    <div className={styles.contentDiv}>
                        <div className={styles.column1}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Consent Date (YYYY-MM-DD) <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <input
                                        value={`${format(consentDate, "yyyy/MM/dd")}`}
                                        readOnly
                                        className={styles.inputFieldSelctedDOB}
                                        onClick={(event) => handleCalClick(event)}
                                    />
                                    {isCalOpen && (
                                        <div className={styles.datePickerWrapper} ref={refOne}>
                                            <Calendar
                                                onChange={item => setConsentDate(item)}
                                                editableDateInputs={false}
                                                showSelectionPreview={true}
                                                months={1}
                                                date={consentDate}
                                                direction="horizontal"
                                                maxDate={addDays(new Date(), 1)}
                                                color={["#aa346f"]}
                                            />
                                            <div className={styles.applyButtonDiv}>
                                                <div className={styles.applyButton} onClick={(event) => handleCalClick(event)}> Confirm </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className={styles.column2}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Full Name of Consent Provider
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={consentProvider} onChange={(e) => setConsentProvider(e.currentTarget.value)} className={styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column3}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Provider of Consent <span style={{ color: 'red', paddingLeft: "2px" }}>*</span>
                                </div>
                                <div className={styles.fieldInput}>
                                    <Select
                                        contentRenderer={customContentRenderer}
                                        className={isProviderOfConsentFill ? styles.dropdown2 : styles.dropdownReq2}
                                        values={providerOfConsent}
                                        onChange={setProviderOfConsent}
                                        options={optionsProviderOfConsent}
                                        color="#4E253A"
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={styles.column4}>
                            <div className={styles.fieldDiv1}>
                                <div className={styles.fieldName}>
                                    Relation to Patient
                                </div>
                                <div className={styles.fieldInput}>
                                    <input type="text" value={relToP} onChange={(e) => setRelToP(e.currentTarget.value)} className={styles.inputFieldSelcted} onFocus={(e) => handleFocus(e)} />
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className={styles.consentDiv}>
                        <div className={styles.fieldName2}>Consent <span style={{ color: 'red', paddingLeft: "2px" }}>*</span></div>
                        <div className={styles.consentConent}>
                            By consenting on this application, you consent to the collection, storage, and usage of your personal health information as described in the consent form. You also consent to the sharing of your personal health information with Aidar Health.
                        </div>
                        <div className={styles.consentButtons}>
                            <label>
                                <input
                                    type="radio"
                                    value="No"
                                    checked={consentSel === "No"}
                                    onChange={onValueChange}
                                />
                                No
                            </label>

                            <label>
                                <input
                                    type="radio"
                                    value="Yes"
                                    checked={consentSel === "Yes"}
                                    onChange={onValueChange}
                                />
                                Yes
                            </label>
                        </div>
                    </div>

                    {showConsentWarn && <div className={styles.consentWarningDiv}>
                        Please fill out the required fields marked with an *
                    </div>}

                    <div className={styles.pageButtonsDiv}>
                        <div className={styles.cancelButton} onClick={handleBackCLick}>Back</div>
                        <div className={styles.saveButton} onClick={handleSubmitFinalPayload}>Submit</div>
                    </div>
                </div>)}
            </div>

            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={styles.modal}
                open={showRegisterNewPatientPopUp}
                onClose={(event, reason) => setShowRegisterNewPatientPopUp(true)}
                hideBackdrop={true}
            >
                {prLoader ? (<><div className={styles.circularProgress1}><CircularProgress thickness={5.0} /></div></>) : prSuccess ?
                    (<>
                        <div className={styles.paperUnassignML}>
                            <div className={styles.menuTitle}>
                                <div className={styles.titleText}>Success!</div>
                                <div>
                                </div>
                            </div>
                            <div className={styles.patientListDiv}>
                                Congrats, you have successfully registered:

                                <div className={styles.subWarningDiv}>
                                    {prMessage}
                                </div>
                            </div>

                            <div className={styles.menuButtons2}>
                                <div className={styles.abortButton} onClick={() => redirectToPatientList()}>
                                    Go to Patients List
                                </div>
                            </div>
                        </div>
                    </>) :
                    (<>
                        <div className={styles.paperUnassignML}>
                            <div className={styles.menuTitle}>
                                <div className={styles.titleText}>Warning</div>
                                <div>
                                    <IconButton aria-label="delete">
                                        <RiCloseCircleFill size={29} onClick={() => setShowRegisterNewPatientPopUp(false)} style={{ color: "#4E253A" }} />
                                    </IconButton>
                                </div>
                            </div>
                            <div className={styles.patientListDiv}>
                                {"Patient registration failed. Please try again."}
                                <div className={styles.subWarningDiv}>
                                </div>
                            </div>

                            <div className={styles.menuButtons2}>
                                <div className={styles.abortButton} onClick={() => setShowRegisterNewPatientPopUp(false)}>
                                    Retry
                                </div>
                            </div>
                        </div>
                    </>)}

            </Modal>

        </div>
    );
}